import { merge, set } from 'lodash'

const getInitialSetting = (value) => ({
  value,
  'read-only': false
})

export const defaultState = {
  features: {},
  settings: {
    synchroTimeframeWindow: getInitialSetting(500),
    competitionName: getInitialSetting(''),
    judges: getInitialSetting({
      requiredElements: 5,
      requiredAI: 5
    }),
    coachCardPushEnabled: getInitialSetting(false),
    timezone: getInitialSetting('+00:00'),
    oneCountryOneRankEnabled: getInitialSetting(true),
    automaticODFGeneration: getInitialSetting(true),
    automaticPublisher: getInitialSetting(true),
    requiredDTC: getInitialSetting(0),
    requiredDATC: getInitialSetting(0),
    testMode: getInitialSetting(false),
    scoresSyncThrottle: getInitialSetting(5000),
    synchroScoresSyncThrottle: getInitialSetting(5000)
  },
  requests: {
    submitSettings: false
  }
}

export const mutations = {
  setFeatureFlags: 'setFeatureFlags',
  setSettings: 'setSettings',
  updateSetting: 'updateSetting',
  isSubmitSaveSettingsPending: 'isSubmitSaveSettingsPending'
}

const handleUserSnap = ({ usersnapApiKey }) => ({
  userSnap: {
    key: usersnapApiKey
  }
})

const handleCopyToken = ({ copyTokenToClipboard }) => ({
  copyTokenToClipboard
})

export const mutationsDefinition = {
  [mutations.setFeatureFlags]: (store, { features }) => {
    store.features = {
      ...handleUserSnap(features),
      ...handleCopyToken(features)
    }
  },
  [mutations.setSettings]: (store, {
    settings: {
      synchroTimeframeWindow,
      competitionName,
      judges,
      coachCardPushEnabled,
      timezone,
      automaticODFGeneration,
      oneCountryOneRankEnabled,
      automaticPublisher,
      requiredDTC,
      requiredDATC,
      testMode,
      scoresSyncThrottle,
      synchroScoresSyncThrottle
    } = {}
  }) => {
    store.settings = {
      synchroTimeframeWindow,
      competitionName,
      judges,
      coachCardPushEnabled,
      timezone,
      automaticODFGeneration,
      oneCountryOneRankEnabled,
      automaticPublisher,
      requiredDATC,
      requiredDTC,
      testMode,
      scoresSyncThrottle,
      synchroScoresSyncThrottle
    }
  },
  [mutations.updateSetting]: (store, { key, value }) => {
    store.settings = merge(
      store.settings,
      {
        ...set({}, key, value)
      })
  },
  [mutations.isSubmitSaveSettingsPending]: (store, { pending }) => {
    store.requests.submitSettings = pending
  }
}
