<template>
    <v-text-field
      v-model="textValue"
      persistent-hint
      single-line
      class="ma-0 px-2 py-1 align-center"
      @change="$emit('changed', textValue)"
      :rules="validation"
      :disabled="isDisabled"
    ></v-text-field>
</template>

<script>
export default {
  name: 'SettingsText',
  emits: ['changed'],
  data () {
    return {
      textValue: this.settingValue
    }
  },
  props: {
    settingValue: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    validation: {
      type: Array,
      default: () => [() => true]
    }
  },
  watch: {
    settingValue (newValue) {
      this.textValue = newValue
    }
  }
}
</script>
