import { isEmpty, map, stubFalse, times, isNil } from 'lodash'
import PanelId from '@/enums/PanelId'

export const defaultState = {
  unitId: null,
  currentUnitParticipants: [],
  participants: [],
  participant: {},
  participantScores: {},
  scoresSent: false,
  isEditMode: false,
  selectedParticipantModifiedScores: {},
  selectedScore: {},
  isSelectedScoreTouched: false,
  unitScoresStructure: {
    panels: {}
  },
  missingJudgeInProgress: {
    elements: {},
    artisticImpression: {}
  }
}

export const mutations = {
  setCurrentUnitParticipants: 'setCurrentUnitParticipants',
  copyParticipantsToCurrentUnitParticipantsIfEmpty: 'copyParticipantsToCurrentUnitParticipantsIfEmpty',
  setParticipants: 'setParticipants',
  setParticipant: 'setParticipant',
  clearParticipants: 'clearParticipants',
  clearParticipant: 'clearParticipant',
  updateParticipantScores: 'updateParticipantScores',
  setParticipantScores: 'setParticipantScores',
  setSynchronizationErrorsTotal: 'setSynchronizationErrorsTotal',
  updateParticipantModifiedScores: 'updateParticipantModifiedScores',
  updateParticipantIrm: 'updateParticipantIrm',
  scoresSent: 'scoresSent',
  isEditMode: 'isEditMode',
  clearCurrentParticipantScores: 'clearCurrentParticipantScores',
  selectParticipantScore: 'selectParticipantScore',
  saveUpdatedScore: 'saveUpdatedScore',
  clearSelectedScore: 'clearSelectedScore',
  clearModifiedJudgeScore: 'clearModifiedJudgeScore',
  setUnitScoresStructure: 'setUnitScoresStructure',
  clearUnitScoresStructure: 'clearUnitScoresStructure',
  setSynchronizationErrors: 'setSynchronizationErrors',
  clearSynchronizationErrors: 'clearSynchronizationErrors',
  setMissingJudgeInProgress: 'setMissingJudgeInProgress',
  updateParticipantStatus: 'updateParticipantStatus'
}

const mapParticipant = (participant = {}) => ({
  id: participant?.id,
  noc: participant?.noc,
  members: participant?.members,
  status: participant?.status,
  totalScore: participant?.totalScore,
  irm: participant?.irm,
  hasCoachCard: participant?.hasCoachCard,
  rank: participant?.rank
})

const throwIfSeatHadScoreAlready = (panelName, seatNumber, removedScore) => {
  if (removedScore) {
    const exceptionMessage = `Invalid scores data. Panel ${panelName} had already scores for seat ${seatNumber}`
    throw new Error(exceptionMessage)
  }
}

const byScore = panelName => (acc, score = {}) => {
  const { seat } = score
  const seatIndex = seat - 1

  throwIfSeatHadScoreAlready(panelName, seat, acc[seatIndex])

  acc[seatIndex] = score

  return acc
}

const toPanelScores = (panel = {}, panelStructure) => !isEmpty(panel)
  ? ({
    ...panel,
    parts: panel.parts.map(part => ({
      ...part,
      scores: part.scores.reduce(byScore(panel.name), [])
    }))
  })
  : ({
    parts: panelStructure.parts.map(part => ({
      degreeOfDifficultyModifiedByChiefRecorder: false,
      isTransition: part.isTransition,
      judgedDegreeOfDifficultyAssistantsFeedback: [],
      name: part.name,
      total: 0,
      factor: part.factor,
      scores: []
    }))
  })

export const mutationsDefinition = {
  [mutations.setCurrentUnitParticipants]: (store, participants) => {
    store.currentUnitParticipants = map(participants, mapParticipant)
  },
  [mutations.copyParticipantsToCurrentUnitParticipantsIfEmpty]: (store) => {
    store.currentUnitParticipants = isEmpty(store.currentUnitParticipants)
      ? store.participants
      : store.currentUnitParticipants
  },
  [mutations.setParticipants]: (store, { unitId, participants } = {}) => {
    store.participants = map(participants, mapParticipant)
    store.unitId = unitId
  },
  [mutations.clearParticipants]: store => {
    store.participants = []
    store.participant = null
    store.participantScores = {}
    store.unitId = null
  },
  [mutations.setParticipant]: (store, participant = {}) => {
    store.participant = mapParticipant(participant)
  },
  [mutations.clearParticipant]: store => {
    store.participant = null
  },
  [mutations.updateParticipantScores] (
    store,
    { participantId, participantScores = {} } = {}
  ) {
    const participant = store.participants.find(a => a.id === participantId)
    const { panels = {}, totalScore, otherPenalties, rank } = participantScores

    const {
      artisticImpression: aiPanelStructure,
      elements: elementsPanelStructure
    } = store.unitScoresStructure.panels

    if (participant) {
      participant.totalScore = totalScore
    }

    const artisticImpression = toPanelScores(panels.artisticImpression, aiPanelStructure)
    const elements = toPanelScores(panels.elements, elementsPanelStructure)
    store.participantScores = {
      panelsScores: { artisticImpression, elements },
      totalScore,
      penalty: otherPenalties,
      rank
    }
  },
  [mutations.updateParticipantModifiedScores] (store) {
    const { panels = {} } = store.unitScoresStructure
    const { artisticImpression, elements } = panels
    const elementsSeatCount = elements?.seatsCount
    const artisticImpressionSeatCount = artisticImpression?.seatsCount

    store.selectedParticipantModifiedScores = {
      artisticImpression: {
        id: artisticImpression?.id,
        parts: artisticImpression?.parts?.map(() => ({
          scores: times(artisticImpressionSeatCount, stubFalse)
        }))
      },
      elements: {
        id: elements?.id,
        parts: elements?.parts?.map(({ isTransition }) => ({
          isTransition,
          scores: times(elementsSeatCount, stubFalse)
        }))
      }
    }
  },
  [mutations.updateParticipantIrm] (store, { participantId, irm }) {
    store.currentUnitParticipants = store.currentUnitParticipants
      .map(p => p.id === participantId ? ({ ...p, irm }) : p)

    store.participants = store.participants
      .map(p => p.id === participantId ? ({ ...p, irm }) : p)

    if (!isEmpty(store.participant) && store.participant.id === participantId) {
      store.participant = {
        ...store.participant,
        irm
      }
    }
  },
  [mutations.clearCurrentParticipantScores] (store, participantId) {
    store.participantScores = {}
    store.participants = store.participants
      .map(p => p.id === participantId ? ({ ...p, totalScore: 0 }) : p)
  },
  [mutations.setParticipantScores] (store, scores = {}) {
    store.participantScores = scores
  },
  [mutations.setSynchronizationErrorsTotal] (store, { synchronizationErrorsTotal }) {
    if (synchronizationErrorsTotal) {
      const newParticipantScores = { ...store.participantScores }
      newParticipantScores.panelsScores.elements.synchronizationErrors = synchronizationErrorsTotal
      store.participantScores = newParticipantScores
    }
  },
  [mutations.scoresSent]: (store, scoresSent = false) => {
    store.scoresSent = scoresSent
  },
  [mutations.isEditMode]: (store, isEditMode = false) => {
    store.isEditMode = isEditMode
  },
  [mutations.selectParticipantScore]: (store, { panelId, partIndex, seat, value }) => {
    store.selectedScore = { panelId, partIndex, seat }
    store.isSelectedScoreTouched = !isNil(value)
  },
  [mutations.clearSelectedScore]: store => {
    store.selectedScore = {}
  },
  [mutations.clearModifiedJudgeScore]: (store, { panel }) => {
    const { id, seat } = panel
    const selectedParticipantModifiedScores = { ...store.selectedParticipantModifiedScores }
    const selectedParticipantModifiedScoresPanel = id === PanelId.Elements
      ? selectedParticipantModifiedScores.elements
      : selectedParticipantModifiedScores.artisticImpression

    for (const judgeScore of selectedParticipantModifiedScoresPanel.parts) {
      judgeScore.scores[seat - 1] = false
    }

    store.selectedParticipantModifiedScores = selectedParticipantModifiedScores
  },
  [mutations.saveUpdatedScore]: (store, newValue) => {
    const { panelId, partIndex, seat } = store.selectedScore
    const updatedScores = { ...store.participantScores }

    const selectedParticipantModifiedScores = store.selectedParticipantModifiedScores
    const updatedScoresPanel = panelId === PanelId.Elements
      ? updatedScores.panelsScores.elements
      : updatedScores.panelsScores.artisticImpression

    const selectedParticipantModifiedScoresPanel = panelId === PanelId.Elements
      ? store.selectedParticipantModifiedScores.elements
      : store.selectedParticipantModifiedScores.artisticImpression

    updatedScoresPanel.parts[partIndex].scores[seat - 1].value = newValue
    selectedParticipantModifiedScoresPanel.parts[partIndex].scores[seat - 1] = true

    store.participantScores = updatedScores
    store.selectedParticipantModifiedScores = selectedParticipantModifiedScores
  },
  [mutations.setUnitScoresStructure]: (store, { panels } = {}) => {
    store.unitScoresStructure = {
      panels
    }
  },
  [mutations.clearUnitScoresStructure]: store => {
    store.unitScoresStructure = {
      panels: {}
    }
  },
  [mutations.setMissingJudgeInProgress]: (store, { panelId, seat, inProgress }) => {
    if (panelId === PanelId.Elements) {
      store.missingJudgeInProgress.elements[seat] = inProgress
    } else {
      store.missingJudgeInProgress.artisticImpression[seat] = inProgress
    }
  },
  [mutations.updateParticipantStatus]: (store, { participantId, status }) => {
    store.currentUnitParticipants = store.currentUnitParticipants
      .map(p => p.id === participantId ? ({ ...p, status }) : p)

    store.participants = store.participants
      .map(p => p.id === participantId ? ({ ...p, status }) : p)

    if (!isEmpty(store.participant)) {
      store.participant = {
        ...store.participant,
        status
      }
    }
  }
}
