<template>
  <v-switch
    v-model='switchValue'
    class='switch'
    @change="$emit('changed', switchValue)"
    :label="settingValue ? switchLabel : ''"
    inset
    :disabled="isDisabled"
  />
</template>

<script>
export default {
  name: 'SettingsSwitch',
  data () {
    return {
      isSnackBarOpened: false,
      switchValue: this.settingValue
    }
  },
  emits: ['changed'],
  props: {
    settingValue: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    switchLabel: {
      type: String,
      required: true
    }
  },
  watch: {
    settingValue (newValue) {
      this.switchValue = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  position: relative;
  vertical-align: center;
  text-align: left;
}

.switch-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
