import { httpget, httppost, httpput, joinUri, newUrl } from '../base-api'
import { forOwn, isNil } from 'lodash'
import { toUri } from './base'

const toScoresUri = ({ unitId, participantId, uri = '' }) =>
  joinUri(
    toUri({
      unitId,
      participantId,
      uri: 'scores'
    }),
    uri)

const validateRestoredValue = data => {
  if (isNil(data)) {
    throw Error('Received no data')
  }

  const { scoreIndex, scores } = data
  if (scoreIndex === undefined || scores === undefined) {
    throw Error(
      `Received data doesn't match schema. Got: ${JSON.stringify(data)}`
    )
  }

  return { scoreIndex, scores }
}

const processReceivedFlatResults = ({ scores = [] } = {}) =>
  scores.map(
    score => {
      const scoreParsed = Number.parseFloat(score)
      return scoreParsed === 10 ? '10' : scoreParsed.toFixed(2)
    }
  )

const processReceivedComplexResults = ({ scores = [] } = {}) =>
  scores.map(
    ({ value, ...rest }) => ({
      ...rest,
      value: (value || value === 0)
        ? (value === 10 ? '10' : value.toFixed(2))
        : value
    })
  )

export default {
  submit: async ({ unitId, participantId, panel, scores }) =>
    await httppost(toScoresUri({ unitId, participantId }), {
      panel,
      scores
    }),

  submitCR: async ({ unitId, participantId, panel, scores }) =>
    await httppost(toScoresUri({ unitId, participantId, uri: 'chief-recorder' }), {
      panel,
      scores
    }),

  save: async ({ unitId, participantId, panel, index, scores }) =>
    await httpput(toScoresUri({ unitId, participantId }), {
      panel,
      scores,
      scoreIndex: index
    }),

  restore: async ({ unitId, participantId, panel }) => {
    const { name, seat } = panel
    return validateRestoredValue(
      await httpget(
        newUrl(toScoresUri({ unitId, participantId }), {
          panel: name,
          seat
        })
      )
    )
  },

  get: async ({ unitId, participantId, panel }) => {
    const { id, seat } = panel
    const result = await httpget(
      newUrl(toScoresUri({ unitId, participantId }), {
        id,
        seat
      }),
      {
        allow: [404]
      }
    )

    return isNil(result) ? null : processReceivedFlatResults(result)
  },

  summary: async ({ unitId, participantId }) => {
    const result = await httpget(toScoresUri({ unitId, participantId }))

    forOwn(
      result.panels,
      panel => {
        for (const part of panel.parts) {
          part.scores = processReceivedComplexResults(part)
        }
      }
    )

    return result
  },

  sendScores: async ({ unitId, participantId, scores }) => {
    return await httppost(
      toScoresUri({ unitId, participantId, uri: 'send' }),
      scores)
  },

  sendPenalties: async ({ unitId, participantId, payload }) => {
    return await httppost(
      toScoresUri({ unitId, participantId, uri: 'penalties' }),
      payload)
  },

  recallScores: async ({ unitId, participantId, panel }) => {
    const { id, seat } = panel

    return await httppost(`${toScoresUri({ unitId, participantId })}/recall`, {
      id,
      seat
    })
  },
  setMissingScores: async ({ unitId, participantId, panel }) => {
    const { id, seat } = panel

    return await httppost(`${toScoresUri({ unitId, participantId })}/missing`, {
      id,
      seat
    })
  },
  calculateTotals: async ({ unitId, participantId }) => {
    return await httppost(`${toScoresUri({ unitId, participantId })}/calculate-totals`)
  },
  generateRandomScores: async ({ unitId, participantId }) => {
    return await httppost(`${toScoresUri({ unitId, participantId })}/random`)
  }
}
