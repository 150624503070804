<template>
  <article>
    <h1
      v-if="isAnyParticipantSelected"
      class="participant-info">
      {{ selectedParticipant.number }}. {{ participantNoc }} {{ participantMembers }}
    </h1>
    <div class="scores-actions-container">
      <v-tooltip
        v-if="shouldShowPresent && presentTooltipText"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn
              class="present-button"
              color="primary"
              disabled
              :loading="isInProgress('present')"
              @click="presentSelected">
              Present
            </v-btn>
          </div>
        </template>
        <span>
          {{ presentTooltipText }}
        </span>
      </v-tooltip>
      <div v-else-if="shouldShowPresent">
        <prompt
          v-if="selectedParticipant.irm && selectedParticipant.irm !== 'Default'"
          :prompt="[
            'Are you sure you want to present participant?',
            'IRM will be waved out.']"
          title="Participant with IRM"
          @onAction="onPresentSelectedPromptAction">
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="present-button"
              color="primary"
              :disabled="!allParticipantsHaveCoachCards"
              :loading="isInProgress('present')">
              Present
            </v-btn>
          </template>
        </prompt>
        <v-btn
          v-else
          class="present-button"
          color="primary"
          :disabled="!allParticipantsHaveCoachCards"
          :loading="isInProgress('present')"
          @click="presentSelected">
          Present
        </v-btn>
      </div>
      <v-btn
        v-ripple
        v-if="shouldShowSendScores"
        :loading='isInProgress("sendScores")'
        color="primary"
        class="send-scores-button"
        @click="sendScores({ unitId, participantId })">
        Send Scores
      </v-btn>
      <span class="send-scores-error">{{sendScoresError}}</span>
      <v-btn
        v-ripple
        v-if="shouldShowCalculateTotals"
        :loading='isInProgress("calculateScores")'
        color="primary"
        @click="calculateTotals({ unitId, participantId })">
        Calculate Totals
      </v-btn>
      <v-btn
        v-ripple
        v-if="shouldShowGenerateRandomScoresButton"
        color="primary"
        class="generate-scores-button"
        :loading='isInProgress("generateRandomScores")'
        @click="generateRandomScores({ unitId, participantId })">
        Generate random scores
      </v-btn>
    </div>
  </article>
</template>

<script>
import { participants, chiefRecorder, configuration } from '@/store/modules'
import { isEmpty, isNil, get, join } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { UnitStatus } from '@/enums/UnitStatus'
import ParticipantStatus from '@/enums/ParticipantStatus'
import Prompt from '@/components/Prompt'

export default {
  name: 'ChiefRecorderHeaderBar',
  components: { Prompt },
  props: {
    unitId: {
      type: String,
      default: null
    },
    unitStatus: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    allParticipantsHaveCoachCards: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async unitId (value) {
      await this.fetchDetails({
        unitId: value,
        participantId: this.participantId
      })
    }
  },
  methods: {
    ...mapActions(
      participants.namespace,
      [
        participants.actions.fetchDetails,
        participants.actions.presentSelected,
        participants.actions.sendScores,
        participants.actions.calculateTotals,
        participants.actions.generateRandomScores
      ]
    ),
    async onPresentSelectedPromptAction (action) {
      if (action === 'ok') {
        await this.presentSelected()
      }
    }
  },
  computed: {
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.performingParticipant,
        participants.getters.selectedParticipant,
        participants.getters.hasAllScores,
        participants.getters.hasTotalsCalculated
      ]
    ),
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.isInProgress,
        chiefRecorder.getters.sendScoresError
      ]
    ),
    ...mapGetters(
      configuration.namespace,
      [
        configuration.getters.allSettings
      ]
    ),
    participantId () {
      return this.selectedParticipant && this.selectedParticipant.id
    },
    participantNoc () {
      return get(this.selectedParticipant, 'noc')
    },
    participantMembers () {
      return join(
        get(this.selectedParticipant, 'members', []),
        ' / ')
    },
    isUnitInProgress () {
      return [
        UnitStatus.Running,
        UnitStatus.Unofficial,
        UnitStatus.Protested
      ].includes(this.unitStatus)
    },
    isUnitInProgressAndBefore () {
      return [
        UnitStatus.Delayed,
        UnitStatus.GettingReady,
        UnitStatus.Postponed,
        UnitStatus.Scheduled,
        UnitStatus.Running
      ].includes(this.unitStatus)
    },
    isParicipantAssessed () {
      return this.isAnyParticipantSelected &&
        this.selectedParticipant.status === ParticipantStatus.Assessed.toLowerCase()
    },
    isParicipantPerforming () {
      return this.isAnyParticipantSelected &&
        this.selectedParticipant.status === ParticipantStatus.Performing.toLowerCase()
    },
    isAfterEditScoresClicked () {
      return !this.readonly && this.isParicipantAssessed
    },
    shouldShowPresent () {
      return this.isUnitInProgressAndBefore &&
        this.isAnyParticipantSelected &&
        !this.isAnybodyPerforming &&
        this.selectedParticipant.couldPerform &&
        !this.isParicipantAssessed
    },
    shouldShowSendScores () {
      return (this.isUnitInProgress &&
        this.isSelectedParticipantPerforming &&
        this.hasAllScores && this.hasTotalsCalculated) || this.isAfterEditScoresClicked
    },
    shouldShowCalculateTotals () {
      return (this.isUnitInProgress &&
        this.isSelectedParticipantPerforming) || this.isAfterEditScoresClicked
    },
    isAnyParticipantSelected () {
      return !isEmpty(this.selectedParticipant)
    },
    isAnybodyPerforming () {
      return this.performingParticipant !== null
    },
    isSelectedParticipantPerforming () {
      const { id: pid } = this.performingParticipant || {}
      const { id: sid } = this.selectedParticipant || {}
      return pid === sid && !isNil(sid) && !isNil(pid)
    },
    presentTooltipText () {
      return this.allParticipantsHaveCoachCards ? '' : 'Unable to run unit as long as it has participants with missing coach cards.'
    },
    shouldShowGenerateRandomScoresButton () {
      return this.allSettings.testMode.value && this.isParicipantPerforming
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/button";
@import "~@/styles/container";
@import "~@/styles/variables";

article {
  @include container('justified');

  & > h1 {
    text-align: left;
  }
}

.scores-actions-container {
  @include container('justified');

  & button {
    margin: 0 0.5rem;
  }
}

.send-scores-error {
  font-size: 0.75rem;
  color: $red;
}
</style>
