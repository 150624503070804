export default Object.freeze({
  Schedule: 'SCHEDULE',
  Opener: 'OPENER',
  StartList: 'START_LIST',
  Judges: 'JUDGES',
  Results: 'RESULTS',
  Freetext: 'FREETEXT',
  Live: 'LIVE',
  Score: 'SCORE_1',
  TechnicalReview: 'TECHNICAL_REVIEW',
  Podium: 'PODIUM',
  TeamComposition: 'TEAM_COMPOSITION'
})
