import { callApi, createSimpleHandler, dispatchGlobal, synchronizationErrors } from '@/api'
import { judge, routine } from '@/store/modules'
import { mutations } from './mutations'

const withUnitId = ({ rootGetters }) => ({
  unitId: rootGetters[`${routine.namespace}/${routine.getters.unitId}`]
})

const withParticipantId = ({ rootGetters }) => ({
  participantId:
    rootGetters[`${routine.namespace}/${routine.getters.participantId}`]
})

const withPanel = ({ rootGetters }) => {
  const { id: panelId, seat } = rootGetters[`${judge.namespace}/${judge.getters.panelBasedIdentifier}`]
  return { panelId, seat }
}

const createFetchSynchronizationErrorsSucceeded = ({ dispatch, commit }) => data => {
  commit(mutations.setSynchronizationErrors, data)
  dispatchGlobal(dispatch, 'fetchSynchronizationErrorsSucceeded', data)
}

const createFetchSynchronizationErrorsFailed = ({ dispatch, commit }) => () => {
  commit(mutations.clearSynchronizationErrors)
  dispatchGlobal(dispatch, 'fetchSynchronizationErrorsFailed')
}

export const actions = {
  sendSynchronizationError: 'sendSynchronizationError',
  fetchSynchronizationErrors: 'fetchSynchronizationErrors',
  changeOutcome: 'changeOutcome'
}

export const actionsDefinition = {
  async [actions.sendSynchronizationError] (store, { mark, timeElapsed }) {
    const payload = {
      ...withUnitId(store),
      ...withParticipantId(store),
      synchronizationError: {
        ...withPanel(store),
        timeElapsed,
        mark
      }
    }

    await callApi(
      async () => await synchronizationErrors.post(payload),
      createSimpleHandler('submittingSynchronizationError', store),
      createSimpleHandler('submitSynchronizationErrorSucceeded', store),
      createSimpleHandler('submitSynchronizationErrorFailed', store)
    )
  },
  async [actions.fetchSynchronizationErrors] (store, { unitId, participantId }) {
    const queryParameters = {
      unitId,
      participantId
    }

    await callApi(
      async () => await synchronizationErrors.get(queryParameters),
      createSimpleHandler('fetchingSynchronizationErrors', store),
      createFetchSynchronizationErrorsSucceeded(store),
      createFetchSynchronizationErrorsFailed(store)
    )
  },
  async [actions.changeOutcome] (store, { unitId, participantId, timeStamp, outcome }) {
    store.commit(
      mutations.updateSynchronizationError,
      { timeStamp, outcome })

    const payload = {
      unitId,
      participantId,
      timeStamp,
      outcome
    }

    await callApi(
      async () => await synchronizationErrors.updateSynchronizationError(payload),
      createSimpleHandler('submittingUpdateSynchronizationError', store),
      createSimpleHandler('submitUpdateSynchronizationErrorSucceeded', store),
      createSimpleHandler('submitUpdateSynchronizationErrorFailed', store)
    )
  }
}
