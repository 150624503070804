<template>
  <article v-if="authorized">
    <GoToCurrentParticipantButton
      :hideIfTargetParticipantSelected="true"
      @clicked="goToCurrentParticipant"/>
    <Menu :authState="authState" skipRole="chief-recorder"/>
    <PageTitle title="Chief Recorder"/>
    <section class="chief-recorder">
      <unit-selector
        :selectedUnitId="selectedUnitId"
        class="units-selector"
        @selectedUnitChanged="onUnitSelected"/>
      <chief-recorder-header-bar
        :readonly="readonly"
        :unit-id="selectedUnitId"
        :unit-status="selectedUnitStatus"
        :allParticipantsHaveCoachCards="allParticipantsHaveCoachCards"
        class="header-bar"/>
      <start-list
        :unit-id="selectedUnitId"
        class="start-list"/>
      <chief-recorder-timers class="timers"/>
      <edit-scores-button
        :visible="shouldShowEditScoresButton"
        class="edit-scores-button"/>
      <chief-recorder-scores
        v-if="selectedUnitId"
        :readonly="readonly"
        :unit-id="selectedUnitId"
        :participant-id="selectedParticipantId"
        class="scores-chief-recorder"/>
    </section>
  </article>
</template>

<script>
import ChiefRecorderHeaderBar from '@/components/chief-recorder/ChiefRecorderHeaderBar'
import ChiefRecorderScores from '@/components/chief-recorder/ChiefRecorderScores'
import ChiefRecorderTimers from '@/components/chief-recorder/ChiefRecorderTimers'
import EditScoresButton from '@/components/chief-recorder/EditScoresButton'
import StartList from '@/components/chief-recorder/StartList'
import UnitSelector from '@/components/chief-recorder/UnitSelector'
import Menu from '@/components/Menu'
import PageTitle from '@/components/PageTitle'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { UnitStatus } from '@/enums/UnitStatus'
import { chiefRecorder, participants, routine, synchronizationErrors, timekeeper, unit, configuration } from '@/store/modules'
import { get, throttle } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { listenOn } from '@/api'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import EventsMap from '@/maps/EventsMap'

export default {
  name: 'ChiefRecorder',
  data () {
    return {
      ...setupAuthorization({ component: this, requiredRoles: ['chief-recorder'] }),
      selectedUnit: null,
      stopListeningHandler: null,
      isDestroyed: false
    }
  },
  async created () {
    if (authorize({ component: this })) {
      const scoresSyncThrottle = this.allSettings.scoresSyncThrottle.value
      const synchroScoresSyncThrottle = this.allSettings.synchroScoresSyncThrottle.value

      const onScoreSubmittedThrottled =
        throttle(async () => this.updateSelectedParticipantScores(), scoresSyncThrottle)
      const onSynchroScoreSubmittedThrottled =
        throttle(async () =>
          await this.fetchSynchronizationErrors({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }), synchroScoresSyncThrottle)

      this.stopListeningHandler = await listenOn({
        isDestroyed: () => this.isDestroyed,
        group: GroupNames.CHIEF_RECORDER,
        onReconnected: () => this.fetchOnlineJudges(),
        events: {
          [EventsMap[Events.SCORE_SUBMITTED]]:
          async ({ unitId, participantId, triggeredBy }) => {
            if (unitId === this.selectedUnitId && participantId === this.selectedParticipantId) {
              if (triggeredBy === GroupNames.CHIEF_RECORDER) {
                await this.updateSelectedParticipantScores()
              } else {
                await onScoreSubmittedThrottled()
              }
            }
          },
          [EventsMap[Events.SYNCHRO_SCORE_SUBMITTED]]:
          async ({ unitId, participantId, triggeredBy }) => {
            if (unitId === this.selectedUnitId && participantId === this.selectedParticipantId) {
              if (triggeredBy === GroupNames.CHIEF_RECORDER) {
                await this.fetchSynchronizationErrors({
                  unitId: this.selectedUnitId,
                  participantId: this.selectedParticipantId
                })
              } else {
                await onSynchroScoreSubmittedThrottled()
              }
            }
          },
          [EventsMap[Events.ROUTINE_FINISHED]]:
            async ({ unitId, participantId }) => {
              if (unitId === this.selectedUnitId && participantId === this.selectedParticipantId) {
                this.updateSelectedParticipantScores()
              }
            },
          [EventsMap[Events.UNIT_STATUS_CHANGED]]:
          async ({ unitId, status }) => {
            this.updateUnitStatus({ unitId, status })

            if (this.selectedUnitId === unitId) {
              await this.fetchAllParticipants({
                unitId
              })
            }
          },
          [EventsMap[Events.DTAC_SCORE_SUBMITTED]]:
            async ({ seat, judgedDegreeOfDifficultyCard }) => {
              this.updateAssistantScores({ seat, judgedDegreeOfDifficultyCard })
            },
          [EventsMap[Events.JUDGE_HAND_IS_RAISED]]:
          async () => await this.fetchRaisedHands({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.JUDGE_HAND_IS_LOWERED]]:
          async () => await this.fetchRaisedHands({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.JUDGE_LEFT]]:
            ({ unitId, judge }) => {
              if (unitId === this.selectedUnitId) {
                this.setOnlineJudge({ judge, online: false })
              }
            },
          [EventsMap[Events.JUDGE_JOINED]]:
          ({ unitId, judge }) => {
            if (unitId === this.selectedUnitId) {
              this.setOnlineJudge({ judge, online: true })
            }
          },
          [EventsMap[Events.ENTRY_TIMER_STARTED]]:
          async () => await this.fetchData({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.DECK_TIMER_STARTED]]:
          async () => await this.fetchData({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.ROUTINE_TIMER_STARTED]]:
          async () => await this.fetchData({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.TIMERS_COMPLETED]]:
          async () => await this.fetchData({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          }),
          [EventsMap[Events.ENTRY_TIMER_STOPPED]]:
          async () => await this.fetchData({
            unitId: this.selectedUnitId,
            participantId: this.selectedParticipantId
          })
        }
      })
    }
  },
  async beforeDestroy () {
    this.isDestroyed = true
    await this.stopListeningHandler?.()
  },
  components: {
    StartList,
    ChiefRecorderHeaderBar,
    Menu,
    UnitSelector,
    ChiefRecorderScores,
    ChiefRecorderTimers,
    EditScoresButton,
    PageTitle,
    GoToCurrentParticipantButton
  },
  computed: {
    readonly () {
      return (this.scoresSent && !this.isEditMode) ||
        this.selectedParticipantStatus === ParticipantStatus.Active
    },
    selectedUnitId () {
      return get(this.selectedUnit, 'id')
    },
    selectedUnitStatus () {
      return get(this.selectedUnit, 'status', null)
    },
    selectedParticipantId () {
      return get(this.selectedParticipant, 'id', null)
    },
    selectedParticipantStatus () {
      return get(this.selectedParticipant, 'status', null)
    },
    shouldShowEditScoresButton () {
      return this.readonly && this.selectedUnitStatus !== UnitStatus.Official.toLowerCase() &&
        this.selectedParticipantStatus === ParticipantStatus.Assessed
    },
    allParticipantsHaveCoachCards () {
      return this.allParticipants.every(p => p.hasCoachCard)
    },
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.scoresSent,
        participants.getters.isEditMode,
        participants.getters.allParticipants,
        participants.getters.selectedParticipant
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      configuration.namespace,
      [
        configuration.getters.allSettings
      ]
    )
  },
  methods: {
    ...mapActions(
      routine.namespace,
      [
        routine.actions.setUnit
      ]
    ),
    ...mapActions(
      participants.namespace,
      [
        participants.actions.fetchDetails,
        participants.actions.updateSelectedParticipantScores,
        participants.actions.updateAssistantScores,
        participants.actions.fetchAllParticipants,
        participants.actions.fetchChiefRecorderConfiguration
      ]
    ),
    ...mapActions(
      synchronizationErrors.namespace,
      [
        synchronizationErrors.actions.fetchSynchronizationErrors
      ]
    ),
    ...mapActions(
      chiefRecorder.namespace,
      [
        chiefRecorder.actions.fetchRaisedHands
      ]
    ),
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchAll,
        unit.actions.fetchOnlineJudges,
        unit.actions.setOnlineJudge,
        unit.actions.updateUnitStatus
      ]
    ),
    ...mapActions(
      timekeeper.namespace,
      [
        timekeeper.actions.fetchData
      ]
    ),
    onUnitSelected (selectedUnit) {
      if (selectedUnit) {
        this.selectedUnit = selectedUnit
        this.setUnit({ id: selectedUnit.id })
      }
    },
    async goToCurrentParticipant (participant) {
      this.selectedUnit = this.currentUnit
      await this.fetchDetails({
        unitId: this.currentUnit.id,
        participantId: participant.id
      })
    }
  },
  watch: {
    async selectedParticipant () {
      await this.fetchChiefRecorderConfiguration({
        unitId: this.selectedUnitId,
        participantId: this.selectedParticipantId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";
@import "~@/styles/variables";

.chief-recorder {
  display: grid;
  grid-template-columns: [first] 1fr [scores] 2fr [end];
  grid-template-rows: [first] 125px [scores] 0fr [start-list] 0.5fr [timers] 125px [edit-scores-button] 1fr [end];
  column-gap: 2rem;

  margin: 0 auto;
  padding: 2rem;
  max-width: $max-view-width;
}

.units-selector {
  grid-column: first / span 1;
  grid-row: first / start-list;
}

.header-bar {
  grid-column: scores / span 1;
  grid-row: first / scores;
}

.start-list {
  @include border($radius: 1rem);

  grid-column: first / span 1;
  grid-row: start-list / timers;

  height: 25rem;

  overflow-x: hidden;
  overflow-y: auto;
}

.timers {
  grid-column: first / scores;
  grid-row: timers;
  margin-top: 20px;
}

.edit-scores-button {
  grid-column: first / scores;
  margin-top: 20px;
}

.scores-chief-recorder {
  grid-column: scores / span 1;
  grid-row: scores / end;
}
</style>
