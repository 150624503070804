import { toApiRootUri } from '@/api/base-api/helpers'
import { http, httpget, httpgetdownload, httppost } from '../base-api'
import { toPrintoutUri, toUri } from './base'

export default {
  listCompetitions: async () => {
    return await http(
      toUri(),
      { method: 'GET' },
      { uriConverter: toApiRootUri }
    )
  },
  generatePrintout: async ({ printoutCode, uri = '', options }) => {
    return await httppost(toPrintoutUri({ printoutCode, uri }), {
      ...options
    })
  },
  printoutStatusesList: async () => {
    return await httpget(toUri({ uri: 'printouts' }))
  },
  downloadPrintout: async ({ uri, filename }) => {
    return await httpgetdownload({ uri, filename })
  },
  registeredJudges: async ({ signal } = {}) => {
    return await httpget(toUri({ uri: 'judges' }), { signal })
  }
}
