import { render, staticRenderFns } from "./Publisher.vue?vue&type=template&id=bf3ca8e8&scoped=true&"
import script from "./Publisher.vue?vue&type=script&lang=js&"
export * from "./Publisher.vue?vue&type=script&lang=js&"
import style0 from "./Publisher.vue?vue&type=style&index=0&id=bf3ca8e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf3ca8e8",
  null
  
)

export default component.exports