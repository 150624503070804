import store from '@/store'
import { cloneDeep, mapValues } from 'lodash'
import { emptyFetchApiActions, emptySubmitApiActions } from './base/api-actions'
import chiefRecorderModule from './chief-recorder'
import configurationModule from './configuration'
import judgeModule from './judge'
import { chiefRecorder, coachCards, configuration, judge, olr, participants, routine, timekeeper, unit, synchronizationErrors, contextSelection } from './modules'
import olrModule from './olr'
import participantsModule from './participants'
import routineModule from './routine'
import timekeeperModule from './timekeeper'
import unitModule from './unit'
import synchronizationErrorsModule from './synchronization-errors'
import contextSelectionModule from './context-selection'
import coachCardsModule from './coach-cards'

export const modulesDefaultStates = () => ({
  coachCards: { ...coachCardsModule.state() },
  chiefRecorder: { ...chiefRecorderModule.state() },
  configuration: { ...configurationModule.state() },
  judge: { ...judgeModule.state() },
  olr: { ...olrModule.state() },
  participants: { ...participantsModule.state() },
  routine: { ...routineModule.state() },
  synchronizationErrors: { ...synchronizationErrorsModule.state() },
  timekeeper: { ...timekeeperModule.state() },
  unit: { ...unitModule.state() }
})

const storeConfiguration = {
  modules: {
    [timekeeper.namespace]: timekeeperModule,
    [routine.namespace]: routineModule,
    [judge.namespace]: judgeModule,
    [unit.namespace]: unitModule,
    [participants.namespace]: participantsModule,
    [chiefRecorder.namespace]: chiefRecorderModule,
    [olr.namespace]: olrModule,
    [configuration.namespace]: configurationModule,
    [synchronizationErrors.namespace]: synchronizationErrorsModule,
    [contextSelection.namespace]: contextSelectionModule,
    [coachCards.namespace]: coachCardsModule
  },
  actions: {
    contextSelected: () => {
      store.replaceState({
        // Keep data of context-selection module
        ...store.state,
        ...modulesDefaultStates()
      })
    },
    ...emptyFetchApiActions('JudgeConfig'),
    ...emptyFetchApiActions('TimekeeperConfig'),
    ...emptyFetchApiActions('ChiefRecorderConfig'),
    ...emptyFetchApiActions('OlrConfig'),
    ...emptyFetchApiActions('RefereeConfig'),
    ...emptyFetchApiActions('OnlineJudges'),
    ...emptyFetchApiActions('JudgeScores'),
    ...emptySubmitApiActions('ModifyJudgeScores'),
    ...emptyFetchApiActions('Scores'),
    ...emptyFetchApiActions('ParticipantScores'),
    ...emptySubmitApiActions('JudgeSingleScore'),
    ...emptyFetchApiActions('UserAuthorize'),
    ...emptySubmitApiActions('RaiseHand'),
    ...emptySubmitApiActions('LowerHand'),
    ...emptyFetchApiActions('RaisedHands'),
    ...emptyFetchApiActions('IsHandRaised'),
    ...emptyFetchApiActions('CurrentRoutineInfo'),
    ...emptyFetchApiActions('AllUnits'),
    ...emptySubmitApiActions('StartUnit'),
    ...emptyFetchApiActions('AllParticipants'),
    ...emptyFetchApiActions('OdfAllParticipants'),
    ...emptyFetchApiActions('CurrentUnitParticipants'),
    ...emptyFetchApiActions('ParticipantDetails'),
    ...emptySubmitApiActions('PresentParticipant'),
    ...emptyFetchApiActions('CurrentUnit'),
    ...emptySubmitApiActions('SendScores'),
    ...emptySubmitApiActions('AbortRoutine'),
    ...emptySubmitApiActions('RecallParticipantScores'),
    ...emptySubmitApiActions('MissingParticipantScores'),
    ...emptySubmitApiActions('Penalties'),
    ...emptySubmitApiActions('ModifyStatus'),
    ...emptySubmitApiActions('ModifyStatusByOlr'),
    ...emptySubmitApiActions('Irm'),
    ...emptySubmitApiActions('OdfArchive'),
    ...emptySubmitApiActions('CoachCardArchive'),
    ...emptyFetchApiActions('RegisteredParticipants'),
    ...emptySubmitApiActions('SaveStartList'),
    ...emptyFetchApiActions('RegisteredJudges'),
    ...emptySubmitApiActions('SaveJudgeList'),
    ...emptyFetchApiActions('AllocatedJudges'),
    ...emptyFetchApiActions('CompetitionPrintoutsStatuses'),
    ...emptyFetchApiActions('EventPrintoutsStatuses'),
    ...emptyFetchApiActions('UnitPrintoutsStatuses'),
    ...emptySubmitApiActions('CompetitionPrintout'),
    ...emptySubmitApiActions('EventPrintout'),
    ...emptySubmitApiActions('UnitPrintout'),
    ...emptySubmitApiActions('DownloadPrintout'),
    ...emptyFetchApiActions('Configuration'),
    ...emptyFetchApiActions('TechnicalControllerConfig'),
    ...emptyFetchApiActions('DegreeOfDifficulty'),
    ...emptySubmitApiActions('DegreeOfDifficulty'),
    ...emptySubmitApiActions('SynchronizationError'),
    ...emptyFetchApiActions('Competitions'),
    ...emptySubmitApiActions('DeleteScheduleEntry'),
    ...emptySubmitApiActions('CreateScheduleEntry'),
    ...emptySubmitApiActions('UpdateScheduleEntry'),
    ...emptyFetchApiActions('SynchronizationErrors'),
    ...emptySubmitApiActions('UpdateSynchronizationError'),
    ...emptySubmitApiActions('SaveSettings'),
    ...emptySubmitApiActions('ResetUnit'),
    ...emptyFetchApiActions('CompetitionCoachCards'),
    ...emptySubmitApiActions('AcceptCoachCard'),
    ...emptySubmitApiActions('RejectCoachCard'),
    ...emptyFetchApiActions('OdfMessages'),
    ...emptySubmitApiActions('GenerateOdfMessage'),
    ...emptySubmitApiActions('SendPublisher'),
    ...emptySubmitApiActions('PscbAllOff'),
    ...emptyFetchApiActions('PscbScenes'),
    ...emptySubmitApiActions('PscbSceneDisplay'),
    ...emptySubmitApiActions('ChangeDegreeOfDifficulty'),
    ...emptySubmitApiActions('CalculateScores'),
    ...emptySubmitApiActions('ConnectPublisher'),
    ...emptySubmitApiActions('DisconnectPublisher'),
    ...emptyFetchApiActions('IsPublisherConnected'),
    ...emptySubmitApiActions('GenerateRandomScores')
  }
}

export default storeConfiguration
export const createStoreConfiguration = () => {
  const { modules, ...store } = storeConfiguration
  return {
    ...cloneDeep(store),
    modules: mapValues(modules, module => cloneDeep(module))
  }
}
