import Events from '../enums/Events'

export default {
  [Events.ENTRY_TIMER_STARTED]: 'EntryTimerStarted',
  [Events.DECK_TIMER_STARTED]: 'DeckTimerStarted',
  [Events.ROUTINE_TIMER_STARTED]: 'RoutineTimerStarted',
  [Events.TIMERS_COMPLETED]: 'TimersCompleted',
  [Events.JUDGE_HAND_IS_RAISED]: 'JudgeHandIsRaised',
  [Events.ROUTINE_STARTED]: 'RoutineStarted',
  [Events.ROUTINE_FINISHED]: 'RoutineFinished',
  [Events.ROUTINE_ABORTED]: 'RoutineAborted',
  [Events.UNIT_STARTED]: 'UnitStarted',
  [Events.SCORE_SUBMITTED]: 'ScoreSubmitted',
  [Events.SCORES_RECALLED]: 'ScoresRecalled',
  [Events.ENTRY_TIMER_STOPPED]: 'EntryTimerStopped',
  [Events.JUDGE_HAND_IS_LOWERED]: 'JudgeHandIsLowered',
  [Events.JUDGE_JOINED]: 'JudgeJoined',
  [Events.JUDGE_LEFT]: 'JudgeLeft',
  [Events.DTAC_SCORE_SUBMITTED]: 'DTACScoreSubmitetd',
  [Events.UNIT_STATUS_CHANGED]: 'UnitStatusChanged',
  [Events.PRINTOUT_FINISHED]: 'PrintoutFinished',
  [Events.SYNCHRO_SCORE_SUBMITTED]: 'SynchroScoreSubmitted'
}
