<template>
  <article v-if="authorized">
    <go-to-current-participant-button/>
    <pscb-all-off-button/>
    <Menu :authState="authState" skipRole="olr-operator"/>
    <page-title title="OLR Operator"/>
    <section class="olr">
      <file-importer
        class="olr__odf-importer"
        name="Competition data import"
        accept=".zip"
        :inProgress="isOdfImportInProgress"
        :result="odfImportResult"
        @fileSelected="onOdfFileSelected"/>
      <file-importer
        class="coach-card-importer"
        name="Coach Card data import"
        accept=".zip,.coachcard,.json"
        :inProgress="isCoachCardImportInProgress"
        :result="coachCardImportResult"
        @fileSelected="onCoachCardFileSelected"/>
      <schedule-list
        class="olr__schedule-list"/>
      <tabbed-navigation
        class="olr__editor"
        :tabsNames='tabsNames'>
          <template slot='StartList'>
            <start-list-editor/>
          </template>
          <template slot='Judges'>
            <judges-allocation-editor/>
          </template>
          <template slot='Printouts'>
            <printouts-generator/>
          </template>
          <template slot='ODF Messages'>
            <odf-messages/>
          </template>
          <template slot='PSCB'>
            <pscb/>
          </template>
          <template slot='Publisher'>
            <publisher/>
          </template>
      </tabbed-navigation>
    </section>
  </article>
</template>

<script>
import Menu from '@/components/Menu.vue'
import PageTitle from '@/components/PageTitle.vue'
import FileImporter from '@/components/FileImporter'
import PrintoutsGenerator from '@/components/olr-operator/PrintoutsGenerator'
import OdfMessages from '@/components/olr-operator/OdfMessages'
import Publisher from '@/components/olr-operator/Publisher'
import ScheduleList from '@/components/olr-operator/ScheduleList'
import StartListEditor from '@/components/olr-operator/StartListEditor'
import JudgesAllocationEditor from '@/components/olr-operator/JudgesAllocationEditor'
import TabbedNavigation from '@/components/olr-operator/TabbedNavigation'
import Pscb from '@/components/olr-operator/Pscb'
import PscbAllOffButton from '@/components/olr-operator/PscbAllOffButton'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { olr, unit } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { listenOn } from '@/api'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import EventsMap from '@/maps/EventsMap'
import { UnitStatus } from '@/enums/UnitStatus'
import ParticipantStatus from '@/enums/ParticipantStatus'

export default {
  name: 'OlrOperator',
  components: {
    FileImporter,
    ScheduleList,
    PrintoutsGenerator,
    OdfMessages,
    Publisher,
    StartListEditor,
    Menu,
    PageTitle,
    TabbedNavigation,
    JudgesAllocationEditor,
    GoToCurrentParticipantButton,
    Pscb,
    PscbAllOffButton
  },
  data () {
    return {
      ...setupAuthorization({ component: this, requiredRoles: ['olr-operator'] }),
      isDestroyed: false
    }
  },
  async created () {
    if (authorize({ component: this })) {
      this.stopListeningHandler = await listenOn({
        isDestroyed: () => this.isDestroyed,
        group: GroupNames.OLR_OPERATOR,
        events: {
          [EventsMap[Events.UNIT_STATUS_CHANGED]]: ({ unitId, status }) => {
            this.updateUnitStatus({ unitId, status })
          },
          [EventsMap[Events.ROUTINE_STARTED]]: ({ unitId }) => {
            this.updateUnitStatus({ unitId, status: UnitStatus.Running })
          },
          [EventsMap[Events.UNIT_STARTED]]: ({ unitId }) => {
            this.updateUnitStatus({ unitId, status: UnitStatus.GettingReady })
          },
          [EventsMap[Events.ROUTINE_FINISHED]]: async ({ unitId, participantId }) => {
            if (this.selectedUnit.id === unitId) {
              this.setParticipantStatus({ participantId, status: ParticipantStatus.Assessed })
            }
          },
          [EventsMap[Events.PRINTOUT_FINISHED]]: async () => {
            await Promise.allSettled([
              this.fetchCompetitionPrintoutsStatuses(),
              this.fetchEventPrintoutsStatuses({
                eventId: this.selectedUnit.eventId
              }),
              this.fetchUnitPrintoutsStatuses({
                unitId: this.selectedUnit.id
              })
            ])
          }
        }
      })
    }
  },
  async beforeDestroy () {
    this.isDestroyed = true
    await this.stopListeningHandler?.()
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.isOdfImportInProgress,
        olr.getters.isCoachCardImportInProgress,
        olr.getters.odfImportResult,
        olr.getters.coachCardImportResult,
        olr.getters.selectedUnit
      ]
    ),
    tabsNames () {
      return ['StartList', 'Judges', 'Printouts', 'ODF Messages', 'PSCB', 'Publisher']
    }
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.importOdf,
        olr.actions.importCoachCard,
        olr.actions.setParticipantStatus,
        olr.actions.fetchCompetitionPrintoutsStatuses,
        olr.actions.fetchEventPrintoutsStatuses,
        olr.actions.fetchUnitPrintoutsStatuses
      ]
    ),
    ...mapActions(
      unit.namespace,
      [
        unit.actions.updateUnitStatus
      ]
    ),
    async onOdfFileSelected (event) {
      await this.importOdf(event)
    },
    async onCoachCardFileSelected (event) {
      await this.importCoachCard(event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.olr {
  display: grid;
  grid-template-columns: [unitlist] 1fr [editor] 2fr [end];
  grid-template-rows: [odfimporter] 1fr [coachcardimporter] 1fr [schedule] 10fr [printouts] 1fr [end];
  column-gap: 2rem;

  width: $max-view-width;
  margin: 0 auto;
  padding: 2rem;

  &__odf-importer {
    grid-column: unitlist / unitlist;
    grid-row: odfimporter / coachcardimporter;
  }

  &__coach-card-importer {
    grid-column: unitlist / unitlist;
    grid-row: coachcardimporter / schedule;
  }

  &__schedule-list {
    grid-column: unitlist / unitlist;
    grid-row: schedule / printouts;
  }

  &__editor {
    grid-column: editor / end;
    grid-row: odfimporter / end;

    padding-top: 2rem;
  }
}
</style>
