<template>
    <v-slider
      v-model="sliderValue"
      class="align-center px-2"
      :max="max"
      :min="min"
      :disabled="isDisabled"
      @change="$emit('changed', sliderValue)">
      <template v-slot:prepend>
        <v-text-field
          v-model.number="sliderValue"
          :hint="units"
          persistent-hint
          single-line
          style="width: 60px"
          class="ma-0 pa-0 align-center"
          type="number"
          :disabled="isDisabled"
          @input="$emit('changed', sliderValue)"
        ></v-text-field>
      </template>
    </v-slider>
</template>

<script>

export default {
  name: 'SettingsSlider',
  emits: ['changed'],
  data () {
    return {
      sliderValue: this.settingValue
    }
  },
  props: {
    settingValue: {
      type: Number,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    units: {
      type: String,
      default: () => ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  watch: {
    settingValue (newValue) {
      this.sliderValue = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: center;
  text-align: left;
}
</style>
