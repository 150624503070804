<template>
  <olr-editor v-if='selectedUnit'
    :unitName='selectedUnit && selectedUnit.name'
    @addToList='moveParticipantToUnit'
    @removeFromList='removeParticipantFromUnit'
    @filterChanged='filterChanged'>
    <template #registred-list>
      <list
        :loading="isRegisteredParticipantsLoadingInProgress"
        :listData='eventRegisteredParticipantsFiltered'
        @itemSelected="onSelectedRegisteredParticipant"
        @itemActionCalled="onDoubleSelectedRegisteredParticipant">
          <template v-for='(participant, index) in eventRegisteredParticipantsFiltered'
            :slot='index'>
            <div :key="participant.id" class="member">
              <v-tooltip bottom v-if="!allEventRegisteredParticipantsFilteredHaveCoachCards">
                <template v-slot:activator="{ attrs, on}">
                  <v-icon
                    v-if="!participant.hasCoachCard"
                    v-on="on"
                    v-bind="attrs"
                    class="member__missing-cc">
                    mdi-alert
                  </v-icon>
                </template>
                Coach card is missing
              </v-tooltip>
              <v-tooltip bottom v-if="participant.hasCoachCard">
                <template v-slot:activator="{ attrs, on}">
                  <a
                    v-on="on"
                    v-bind="attrs"
                    target="_blank"
                    :href="getCcbUpdateLink(participant)"
                    class="member__edit-cc-link"
                    @click.stop>
                    <v-icon class="member__edit-cc">mdi-clipboard-edit-outline</v-icon>
                  </a>
                </template>
                Edit CCB
              </v-tooltip>
              <span class='member__noc'>
                {{participant.noc}}
              </span>
              <span class='member__name'>
                {{displayMember(participant.members, participant.rank)}}
              </span>
            </div>
          </template>
      </list>
    </template>
    <template #allocated-list>
      <list
        :loading="isUnitParticipantsLoadingInProgress"
        :listData='unitParticipants'
        @itemSelected="onSelectedUnitParticipant"
        @itemActionCalled="onDoubleSelectedUnitParticipant">
          <template v-for='(participant, index) in unitParticipants'
            :slot='index'>
            <div :key="participant.id" class="member">
              <v-tooltip bottom v-if="!allUnitParticipantsHaveCoachCards">
                <template v-slot:activator="{ attrs, on}">
                  <v-icon
                    v-if="!participant.hasCoachCard"
                    v-on="on"
                    v-bind="attrs"
                    class="member__missing-cc">
                    mdi-alert
                  </v-icon>
                </template>
                Coach card is missing
              </v-tooltip>
              <v-tooltip bottom v-if="participant.hasCoachCard">
                <template v-slot:activator="{ attrs, on}">
                  <a
                    v-on="on"
                    v-bind="attrs"
                    target="_blank"
                    :href="getCcbUpdateLink(participant)"
                    class="member__edit-cc-link"
                    @click.stop>
                    <v-icon class="member__edit-cc">mdi-clipboard-edit-outline</v-icon>
                  </a>
                </template>
                Edit CCB
              </v-tooltip>
              <span class='member__noc'>
                {{index + 1}}
                {{participant.noc}}
              </span>
              <span class='member__name'>
                {{displayMember(participant.members)}}
              </span>
            </div>
          </template>
      </list>
    </template>
    <template #buttons>
      <div>
        <div v-if="shouldDisplayOlympicButtons">
          <v-btn
            color="primary"
            small
            @click="resetOlympicDrawOrderClicked"
            class="start-list-editor__button-reset-olympic-draw-order">
            Reset Olympic Draw Order
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="olympicDrawClicked"
            class="start-list-editor__button-olympic-draw">
            Olympic Draw
          </v-btn>
        </div>
        <div>
          <v-btn
            color="primary"
            small
            @click="addParticipantsWithCoachCardClicked"
            class="start-list-editor__button-add-with-cc">
            Add Participants with CC
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="randomDrawClicked"
            class="start-list-editor__button-random-draw">
            Random Draw
          </v-btn>
        </div>
      </div>
      <div>
        <v-btn
          color="red"
          small
          @click="clearStartListClicked"
          class="start-list-editor__button-clear-startlist">
          Clear Startlist
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="saveStartListClicked"
          :loading="isSaveStartListInProgress"
          class="start-list-editor__button-save-startlist">
          Save Startlist
        </v-btn>
      </div>
    </template>
  </olr-editor>
</template>

<script>
import { olr, unit } from '@/store/modules'
import { mapGetters, mapActions } from 'vuex'
import { join, shuffle, isNil, isEmpty, sortBy, groupBy, mapValues } from 'lodash'
import List from '@/components/List.vue'
import OlrEditor from '@/components/olr-operator/OlrEditor'
import { phases } from '@/api'
const olympicEvents = new Set([
  'SWAOTEAM8-------------------------',
  'SWAWTEAM2-------------------------'
])

export default {
  name: 'StartListEditor',
  data: () => ({
    unitParticipants: [],
    eventRegisteredParticipants: [],
    selectedUnitParticipant: null,
    selectedRegisteredParticipant: null,
    filter: ''
  }),
  components: {
    List,
    OlrEditor
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.selectedUnit,
        olr.getters.selectedRegisteredParticipants,
        olr.getters.isSaveStartListInProgress,
        olr.getters.isRegisteredParticipantsLoadingInProgress,
        olr.getters.isUnitParticipantsLoadingInProgress,
        olr.getters.allParticipants
      ]
    ),
    eventRegisteredParticipantsFiltered () {
      if (this.filter) {
        return this.eventRegisteredParticipants
          .filter(a => a.members.some(participant => participant.toLowerCase()
            .includes(this.filter.toLowerCase())))
      }

      return this.eventRegisteredParticipants
    },
    allEventRegisteredParticipantsFilteredHaveCoachCards () {
      return this.eventRegisteredParticipantsFiltered.filter(p => !p.hasCoachCard).length === 0
    },
    allUnitParticipantsHaveCoachCards () {
      return this.unitParticipants.filter(p => !p.hasCoachCard).length === 0
    },
    shouldDisplayOlympicButtons () {
      return olympicEvents.has(this.selectedUnit.eventId)
    }
  },
  watch: {
    selectedUnit: {
      handler: async function () {
        if (!isEmpty(this.selectedUnit)) {
          await Promise.allSettled([
            this.fetchRegisteredParticipants({
              phaseId: this.selectedUnit.phaseId,
              unitId: this.selectedUnit.id
            }),
            this.fetchAllParticipants({
              unitId: this.selectedUnit.id
            })
          ])
        }
      },
      immediate: true
    },
    selectedRegisteredParticipants: {
      handler: function (newVal) {
        if (this.selectedUnit) {
          this.eventRegisteredParticipants = !olympicEvents.has(this.selectedUnit.eventId)
            ? newVal
            : this.getOlympicOrder(newVal)
        }
      },
      immediate: true
    },
    allParticipants: {
      handler: function (newVal) { this.unitParticipants = newVal },
      immediate: true
    }
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.fetchRegisteredParticipants,
        olr.actions.fetchAllParticipants
      ]
    ),
    ...mapActions(
      unit.namespace,
      [
        unit.actions.saveStartList
      ]
    ),
    displayMember (members, rank) {
      return rank ? `${join(members, ' / ')} (${rank})` : join(members, ' / ')
    },
    async saveStartListClicked () {
      await this.saveStartList({
        unitId: this.selectedUnit.id,
        participantsIds: this.unitParticipants.map(participant => participant.id)
      })
    },
    randomDrawClicked () {
      const shuffledRegisteredParticipants = shuffle(this.eventRegisteredParticipantsFiltered)
      this.unitParticipants.push(...shuffledRegisteredParticipants)
      this.eventRegisteredParticipants = []
    },
    addParticipantsWithCoachCardClicked () {
      const registeredParticipantsWithCC = this.eventRegisteredParticipantsFiltered
        .filter(p => p.hasCoachCard)
      const orderedByCountryRegisteredParticipantsWithCc = sortBy(registeredParticipantsWithCC,
        p => [p.noc, p.members[0]])
      this.unitParticipants.push(...orderedByCountryRegisteredParticipantsWithCc)
      this.eventRegisteredParticipants = this.eventRegisteredParticipantsFiltered
        .filter(p => !p.hasCoachCard)
    },
    clearStartListClicked () {
      this.eventRegisteredParticipants = !olympicEvents.has(this.selectedUnit.eventId)
        ? this.eventRegisteredParticipants.concat(this.unitParticipants)
        : this.getOlympicOrder(this.unitParticipants)

      this.unitParticipants = []
    },
    resetOlympicDrawOrderClicked () {
      const registeredNotAssignedParticipants = this.selectedRegisteredParticipants
        .filter(p => !this.unitParticipants.some(up => up.id !== p.id))
      this.eventRegisteredParticipants = this.getOlympicOrder(registeredNotAssignedParticipants)
    },
    olympicDrawClicked () {
      const middleIndex = Math.floor(this.eventRegisteredParticipantsFiltered.length / 2)
      const firstHalf = this.eventRegisteredParticipantsFiltered.slice(0, middleIndex)
      const secondHalf = this.eventRegisteredParticipantsFiltered.slice(middleIndex)

      const olympicDrawResult = shuffle(firstHalf).concat(shuffle(secondHalf))
      this.unitParticipants.push(...olympicDrawResult)
      this.eventRegisteredParticipants = []
    },
    moveParticipantToUnit () {
      if (!isNil(this.selectedRegisteredParticipant)) {
        this.eventRegisteredParticipants = this.eventRegisteredParticipants
          .filter(x => x.id !== this.selectedRegisteredParticipant.id)

        this.unitParticipants.push(this.selectedRegisteredParticipant)
        this.selectedRegisteredParticipant = null
      }
    },
    removeParticipantFromUnit () {
      if (!isNil(this.selectedUnitParticipant)) {
        this.unitParticipants = this.unitParticipants
          .filter(x => x.id !== this.selectedUnitParticipant.id)

        this.eventRegisteredParticipants.push({ ...this.selectedUnitParticipant })
        this.selectedUnitParticipant = null
      }
    },
    onSelectedRegisteredParticipant (selection) {
      this.selectedRegisteredParticipant = selection.item
    },
    onDoubleSelectedRegisteredParticipant () {
      this.moveParticipantToUnit()
    },
    onDoubleSelectedUnitParticipant () {
      this.removeParticipantFromUnit()
    },
    onSelectedUnitParticipant (selection) {
      this.selectedUnitParticipant = selection.item
    },
    filterChanged (value) {
      this.filter = value
    },
    getCcbUpdateLink (participant) {
      const participantId = participant.id
      const phaseId = this.selectedUnit.phaseId
      const unitId = this.selectedUnit.id
      return phases.updateParticipantCoachCardUri({ phaseId, unitId, participantId })
    },
    getOlympicOrder (data) {
      let olympicDrawOrder = []
      const rankGroups = groupBy(data, 'rank')
      const sortedRankGroups = mapValues(rankGroups, (group) => shuffle(group))

      for (const rankGroup of Object.values(sortedRankGroups)) {
        olympicDrawOrder = olympicDrawOrder.concat(rankGroup)
      }
      return olympicDrawOrder.reverse()
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/typology";
@import "~@/styles/variables";

.member {
  display: flex;
  flex-direction: row;
  position: relative;

  &__name {
    @include value-l5;
    text-wrap: wrap;
  }

  &__noc {
    @include value-l6;
  }

  &__missing-cc {
    color: $orange;
  }

  &__edit-cc {
    @include value-l4;
    margin-right: 0.2rem;
    color: $purple;
  }

   &__edit-cc-link {
    text-decoration: none;
  }
}
</style>
