import { isEmpty } from 'lodash'

export const getters = Object.freeze({
  isUserSnapEnabled: 'isUserSnapEnabled',
  getUserSnapUri: 'getUserSnapUri',
  isCopyTokenToClipboardEnabled: 'isCopyTokenToClipboardEnabled',
  isSettingsSaveInProgress: 'isSettingsSaveInProgress',
  allSettings: 'allSettings'
})

const getUserSnapApiKey = store => store?.features?.userSnap?.key ?? ''
export const gettersDefinition = Object.freeze({
  [getters.isUserSnapEnabled] (store) {
    return !isEmpty(getUserSnapApiKey(store))
  },

  [getters.getUserSnapUri] (store) {
    const key = getUserSnapApiKey(store)
    return `https://widget.usersnap.com/global/load/${key}?onload=onUsersnapCXLoad`
  },

  [getters.isCopyTokenToClipboardEnabled] (store) {
    return store?.features?.copyTokenToClipboard ?? false
  },

  [getters.isSettingsSaveInProgress] ({ requests }) {
    return requests.submitSettings
  },

  [getters.allSettings] ({ settings }) {
    return settings
  }
})
