<template>
  <span class="d-flex justify-space-between align-center">
    <settings-switch
      :settingValue="coachCardPushEnabled"
      :switch-label='ccbCompetitionUri'
      :isDisabled='isDisabled'
      setting-name='coachCardPushEnabled'
      @changed="(newValue) => $emit('changed', newValue)"
      />
    <v-btn
      v-show='coachCardPushEnabled'
      small
      color="primary"
      class="switch-button ma-2"
      @click="copyUriToClipboard">
      {{"Copy public URL to clipboard"}}
    </v-btn>
    <v-snackbar
      v-model="isSnackBarOpened">
      {{ 'Public URL copied to the clipboard' }}
    </v-snackbar>
  </span>
</template>

<script>
import SettingsSwitch from '@/components/settings/SettingsSwitch.vue'
import { COACHCARD_BUILDER_URI } from '@/api/apiconsts'

export default {
  name: 'CoachCardPushEnabledSettingsSwitch',
  components: { SettingsSwitch },
  emits: ['changed'],
  data () {
    return {
      isSnackBarOpened: false
    }
  },
  props: {
    coachCardPushEnabled: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    copyUriToClipboard () {
      this.isSnackBarOpened = true
      navigator.clipboard.writeText(this.switchLabel)
    }
  },
  computed: {
    ccbCompetitionUri () {
      return new URL(this.selectedContext, COACHCARD_BUILDER_URI).href
    }
  }
}
</script>
