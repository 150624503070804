import { forEach, isArray, isBoolean, isFunction, isNil, isNumber, isEmpty, overSome, reject, entries } from 'lodash'
import HubEventsMap from '@/maps/HubEventsMap'
import HubEvents from '@/enums/HubEvents'
import { getConnection } from './signalR'

const invalidInvocationString = 'Invalid invocation.'
const missingArgsString = (...args) => `Missing ['${args.join("', '")}'].`

function validateMissingArguments (config) {
  const missingArgs = []

  if (isNil(config)) {
    missingArgs.push('group')
    missingArgs.push('events')
    missingArgs.push('isDestroyed')
  } else {
    const { group, events, isDestroyed } = config
    if (isNil(group)) {
      missingArgs.push('group')
    }
    if (isNil(events)) {
      missingArgs.push('events')
    }
    if (isNil(isDestroyed)) {
      missingArgs.push('isDestroyed')
    }
  }

  if (missingArgs.length) {
    throw Error(
      `${invalidInvocationString} ${missingArgsString(...missingArgs)}`
    )
  }
}

const anythingButObject = overSome([
  isNil, isEmpty, isArray, isFunction, isBoolean, isNumber
])

const validateIncorrectEvent = ({ events }) => {
  if (
    anythingButObject(events)
  ) {
    throw Error(`${invalidInvocationString} ${missingArgsString('events')}`)
  }

  const allButFunctions = reject(entries(events), ([_, value]) =>
    isFunction(value)
  )
  if (!isEmpty(allButFunctions)) {
    const quotedNames = allButFunctions.map(([key]) => `'events.${key}'`)
    throw Error(
      `${invalidInvocationString} [${quotedNames.join(', ')}] is not a function.`
    )
  }
}

const validateArguments = config => {
  validateMissingArguments(config)
  validateIncorrectEvent(config)

  return config
}

const createStopListeningHandler = (connection, config) => async () => {
  forEach(
    entries(config.events),
    ([event, callback]) => connection.off(event, callback))

  await connection.invoke(
    HubEventsMap[HubEvents.LEAVE_GROUP],
    config.group)
}
export const listenOn = async (config) => {
  const connection = getConnection()

  validateArguments(config)

  forEach(
    entries(config.events),
    ([event, callback]) => connection.on(event, callback))

  connection.onreconnected(async () => {
    if (config.isDestroyed()) {
      return
    }

    await connection.invoke(
      HubEventsMap[HubEvents.JOIN_GROUP],
      config.group)
    if (isFunction(config.onReconnected)) {
      config.onReconnected()
    }
  })

  await connection.invoke(
    HubEventsMap[HubEvents.JOIN_GROUP],
    config.group)

  return createStopListeningHandler(connection, config)
}
