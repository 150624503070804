import { toEventsBaseUri, toPrintoutUri } from './base'
import { httpdelete, httpget, httppost, httpput } from '../base-api'

export default {
  generatePrintout: async ({ eventId, printoutCode, options }) => {
    return await httppost(toPrintoutUri({ eventId, printoutCode }), {
      ...options
    })
  },
  printoutStatusesList: async ({ eventId }) => {
    return await httpget(toEventsBaseUri({ eventId, uri: 'printouts' }))
  },
  delete: async ({ eventId }) => {
    return await httpdelete(toEventsBaseUri({ eventId }))
  },
  createEvent: async ($event) => {
    return await httppost(toEventsBaseUri(), $event)
  },
  updateEvent: async ($event) => {
    return await httpput(toEventsBaseUri(), $event)
  }
}
