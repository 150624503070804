import { debounce, isNil } from 'lodash'
import { callApi, createSimpleHandler, degreeOfDifficulty, dispatchGlobal } from '@/api'
import { routine, unit } from '@/store/modules'
import { getters } from '../getters'
import { mutations } from '../mutations'
import { initializeDifficultyTechnicalControllerScores } from '../shared'
import actions from './names'

const createFetchDegreeOfDifficultySucceededHandler = ({
  dispatch,
  commit
}) => scores => {
  commit(mutations.updateDtcScores, scores)
  dispatchGlobal(dispatch, 'fetchDegreeOfDifficultySucceeded')
}

const createSubmittingDegreeOfDifficultyHandler = ({ commit, dispatch }, index) => () => {
  commit(mutations.setInProgress, { index, inProgress: true })
  dispatchGlobal(dispatch, 'submittingDegreeOfDifficulty')
}

const createSubmitDegreeOfDifficultySucceededHandler = ({ commit, dispatch }, index) => () => {
  commit(mutations.setInProgress, { index, inProgress: false })
  dispatchGlobal(dispatch, 'submitDegreeOfDifficultySucceeded')
}

const createSubmitDegreeOfDifficultyFailedHandler = ({ commit, dispatch }, index) => () => {
  commit(mutations.setInProgress, { index, inProgress: false })
  dispatchGlobal(dispatch, 'submitDegreeOfDifficultyFailed')
}

const withUnitId = store => ({
  unitId: unit.globals.getters[unit.getters.currentUnit](store)?.id ?? null
})

const withParticipantId = store => ({
  participantId: routine.globals.getters[routine.getters.participantId](store)
})

const updateDegreeOfDifficulty = debounce(async (store, index) => {
  const payload = {
    ...withUnitId(store),
    ...withParticipantId(store),
    marks: store.getters[getters.marksAlignedToCoachCardParts]
  }
  return callApi(
    async () => degreeOfDifficulty.post(payload),
    createSubmittingDegreeOfDifficultyHandler(store, index),
    createSubmitDegreeOfDifficultySucceededHandler(store, index),
    createSubmitDegreeOfDifficultyFailedHandler(store, index)
  )
}, 1000)

const updateAssistantDegreeOfDifficulty = debounce(async (store, index, seat) => {
  const payload = {
    ...withUnitId(store),
    ...withParticipantId(store),
    marks: store.getters[getters.assistantMarksAlignedToCoachCardParts],
    seat
  }

  callApi(
    async () => degreeOfDifficulty.postAssistant(payload),
    createSubmittingDegreeOfDifficultyHandler(store, index),
    createSubmitDegreeOfDifficultySucceededHandler(store, index),
    createSubmitDegreeOfDifficultyFailedHandler(store, index)
  )
}, 1000)

export default {
  async [actions.fetchMarks] (store) {
    const payload = {
      ...withUnitId(store),
      ...withParticipantId(store)
    }

    return await callApi(
      async () => degreeOfDifficulty.get(payload),
      createSimpleHandler('fetchingDegreeOfDifficulty', store),
      createFetchDegreeOfDifficultySucceededHandler(store),
      createSimpleHandler('fetchDegreeOfDifficultyFailed', store)
    )
  },

  async [actions.updateMarkAndSubmitAll] (store, data) {
    if (isNil(data)) {
      return
    }

    const { index, mark } = data
    store.commit(mutations.updateDtcScore, { index, mark })

    return await updateDegreeOfDifficulty(store, index)
  },

  async [actions.updateAssistantMarkAndSubmitAll] (store, data) {
    if (isNil(data)) {
      return
    }

    const panelInfo = store.getters[getters.panelInfo]
    const seat = panelInfo?.seat

    if (isNil(seat)) {
      return
    }

    const { index, mark } = data
    store.commit(mutations.updateAssistantScore, { seat, index, mark })

    await updateAssistantDegreeOfDifficulty(store, index, seat)
  },

  async [actions.updateAssistantScores] (store, { seat, judgedDegreeOfDifficultyCard }) {
    const { dtcScores } = store.state
    const newScores = { ...dtcScores }
    const datcCount = store.datcCount

    initializeDifficultyTechnicalControllerScores(newScores, datcCount)

    newScores.assistantsDifficultyTechnicalControllerFeedback[seat - 1] =
      judgedDegreeOfDifficultyCard
    store.commit(mutations.updateDtcScores, newScores)
  },

  [actions.clearMarks] (store) {
    store.commit(mutations.updateDtcScores, [])
  }
}
