import { getSelectedParticipant } from '@/components/helpers'
import { actions } from './actions'
import { mutations } from './mutations'
import ParticipantStatus from '@/enums/ParticipantStatus'

export const subscribe = ({ store, dispatchLocal, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'submitPenaltiesSucceeded':
      case 'submitMissingParticipantScoresSucceeded': {
        const { unitId, participantId } = action.payload
        dispatchLocal(actions.fetchParticipantScores, {
          unitId,
          participantId
        })
        break
      }

      case 'submitIrmSucceeded': {
        const { unitId, participantId, irm, participantIdToSelect } = action.payload
        commitLocal(mutations.updateParticipantIrm, { participantId, irm })
        dispatchLocal(actions.fetchAllParticipants, { unitId, participantIdToSelect })
        break
      }

      case 'submitAbortRoutineSucceeded': {
        const { participantId } = action.payload
        commitLocal(mutations.clearCurrentParticipantScores, participantId)
        commitLocal(
          mutations.updateParticipantStatus,
          { participantId, status: ParticipantStatus.Active })

        break
      }

      case 'submitModifyJudgeScoresFailed': {
        const { unitId, participantId } = action.payload
        dispatchLocal(actions.fetchDetails, {
          unitId,
          participantId
        })

        break
      }

      case 'fetchAllParticipantsSucceeded': {
        const { unitId, participantIdToSelect, participants } = action.payload
        const selectedParticipant = getSelectedParticipant(participants, participantIdToSelect)
        dispatchLocal(actions.fetchDetails, {
          unitId,
          participantId: selectedParticipant.id
        })

        break
      }

      case 'submitSendScoresSucceeded': {
        const { unitId, participantId } = action.payload
        dispatchLocal(actions.fetchAllParticipants, {
          unitId,
          participantIdToSelect: participantId
        })
        dispatchLocal(actions.fetchParticipantScores, {
          unitId,
          participantId
        })
        commitLocal(
          mutations.updateParticipantStatus,
          { participantId, status: ParticipantStatus.Assessed })
        break
      }

      case 'fetchCurrentRoutineInfoSucceeded':
      case 'submitSaveStartListSucceeded': {
        const { unitId } = action.payload
        dispatchLocal(actions.fetchAllParticipants, { unitId })
        break
      }

      case 'fetchCurrentUnitSucceeded': {
        const { id: unitId } = action.payload
        dispatchLocal(actions.fetchCurrentUnitParticipants, { unitId })
        break
      }

      case 'fetchCurrentUnitFailed':
        commitLocal(mutations.setCurrentUnitParticipants, [])
        break

      case 'submitResetUnitSucceeded':
        dispatchLocal(actions.fetchAllParticipants, action.payload)
        break

      case 'submitPresentParticipantSucceeded': {
        const { participantId } = action.payload
        commitLocal(mutations.copyParticipantsToCurrentUnitParticipantsIfEmpty)
        commitLocal(mutations.updateParticipantScores, { participantId })
        commitLocal(mutations.updateParticipantStatus,
          { participantId, status: ParticipantStatus.Performing })
        commitLocal(mutations.updateParticipantIrm, { participantId, irm: undefined })
        break
      }

      case 'fetchSynchronizationErrorsSucceeded': {
        const synchronizationErrorsTotal = action.payload
          .reduce((total, error) => total + error.deduction, 0)
        commitLocal(mutations.setSynchronizationErrorsTotal, {
          synchronizationErrorsTotal
        })
        break
      }
    }
  })
}
