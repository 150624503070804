import { callApi, dispatchGlobal, unit } from '@/api'
import { UnitStatus } from '@/enums/UnitStatus'
import { getters } from './getters'
import { mutations } from './mutations'
import { retrieveCancellationTokenFor } from '@/store/unit/cancellation-token-provider'

export const actions = {
  fetchOnlineJudges: 'fetchOnlineJudges',
  setOnlineJudge: 'setOnlineJudge',
  fetchAll: 'fetchAll',
  fetchCurrent: 'fetchCurrent',
  postResetUnit: 'postResetUnit',
  startUnit: 'startUnit',
  modifyStatus: 'modifyStatus',
  saveStartList: 'saveStartList',
  saveJudgeList: 'saveJudgeList',
  unitStatusChangeStarted: 'unitStatusChangeStarted',
  unitStatusChangeFinished: 'unitStatusChangeFinished',
  updateUnitStatus: 'updateUnitStatus'
}

const fetchingCurrentUnitAction = 'fetchingCurrentUnit'
const fetchingOnlineJudgesAction = 'fetchingOnlineJudges'
const createSimpleHandler = (
  actionName,
  { dispatch },
  payload = undefined
) => () => dispatchGlobal(dispatch, actionName, payload)

const createFetchAllUnitsSucceeded = ({ dispatch, commit }) => data => {
  commit(mutations.setUnits, data)
  dispatchGlobal(dispatch, 'fetchAllUnitsSucceeded')
}

const createSubmittingFetchCurrent = ({ dispatch, commit }) => data => {
  commit(mutations.setIsInProgress, { element: fetchingCurrentUnitAction, pending: true })
  dispatchGlobal(dispatch, fetchingCurrentUnitAction, data)
}

const createFetchCurrentSucceeded = ({ dispatch, commit }) => data => {
  commit(mutations.setCurrentUnit, data)
  commit(mutations.setIsInProgress, { element: fetchingCurrentUnitAction, pending: false })
  dispatchGlobal(dispatch, 'fetchCurrentUnitSucceeded', data)
}

const createFetchCurrentFailed = ({ dispatch, commit }) => error => {
  commit(mutations.setCurrentUnit, null)
  commit(mutations.setIsInProgress, { element: fetchingCurrentUnitAction, pending: false })
  dispatchGlobal(dispatch, 'fetchCurrentUnitFailed', error)
}

const createStartUnitHandler = (
  { commit, dispatch, getters: localGetters },
  { unitId }
) => () => {
  const alteredUnit = find(localGetters[getters.allUnits], ['id', unitId])
  commit(mutations.updateUnit, {
    id: unitId,
    status: UnitStatus.Running
  })
  dispatchGlobal(dispatch, 'submittingStartUnit')
  return alteredUnit
}

const createSubmittingFetchOnlineJudges = ({ dispatch, commit }) => data => {
  commit(mutations.setIsInProgress, { element: fetchingOnlineJudgesAction, pending: true })
  dispatchGlobal(dispatch, fetchingOnlineJudgesAction, data)
}

const createFetchOnlineJudgesSucceeded = ({ commit, dispatch }) => judges => {
  commit(mutations.setIsInProgress, { element: fetchingOnlineJudgesAction, pending: false })
  commit(mutations.setJudgesOnline, judges)
  dispatchGlobal(dispatch, 'fetchOnlineJudgesSucceeded')
}

const createFetchOnlineJudgesFailed = ({ commit, dispatch }) => judges => {
  commit(mutations.setIsInProgress, { element: fetchingOnlineJudgesAction, pending: false })
  dispatchGlobal(dispatch, 'fetchOnlineJudgesFailed')
}

const createStartUnitFailedHandler = ({ commit, dispatch }) => (
  exception,
  beforeResult
) => {
  commit(mutations.updateUnit, beforeResult)
  dispatchGlobal(dispatch, 'submitStartUnitFailed')
}

export const actionsDefinition = {
  async [actions.fetchOnlineJudges] (store, { unitId }) {
    const payload = { unitId }
    const token = retrieveCancellationTokenFor(store, { unit: unitId })
    if (!unitId) {
      return
    }
    await callApi(
      async () => {
        return await unit.onlineJudges(payload, token)
      },
      createSubmittingFetchOnlineJudges(store),
      createFetchOnlineJudgesSucceeded(store),
      createFetchOnlineJudgesFailed(store)
    )
  },
  [actions.setOnlineJudge] (store, { judge, online }) {
    store.commit(mutations.setJudgeOnline, { judge, online })
  },
  async [actions.fetchAll] (store) {
    await callApi(
      async () => {
        return await unit.list()
      },
      createSimpleHandler('fetchingAllUnits', store),
      createFetchAllUnitsSucceeded(store),
      createSimpleHandler('fetchAllUnitsFailed', store)
    )
  },
  async [actions.startUnit] (store, { unitId }) {
    await callApi(
      async () => {
        return unit.start({ unitId })
      },
      createStartUnitHandler(store, { unitId }),
      createSimpleHandler('submitStartUnitSucceeded', store),
      createStartUnitFailedHandler(store)
    )
  },
  async [actions.fetchCurrent] (store) {
    const { getters: localGetters } = store
    const isInProgress = localGetters[getters.isInProgress](fetchingCurrentUnitAction)
    if (isInProgress) {
      return
    }
    await callApi(
      async () => {
        return unit.current()
      },
      createSubmittingFetchCurrent(store),
      createFetchCurrentSucceeded(store),
      createFetchCurrentFailed(store)
    )
  },
  async [actions.modifyStatus] (store, { unitId, expectedStatus }) {
    await callApi(
      async () => {
        return unit.modifyStatus({ unitId, expectedStatus })
      },
      createSimpleHandler('submittingModifyStatus', store),
      createSimpleHandler('submitModifyStatusSucceeded', store),
      createSimpleHandler('submitModifyStatusFailed', store)
    )
  },
  async [actions.saveStartList] (store, { unitId, participantsIds }) {
    const payload = { unitId }
    await callApi(
      async () => {
        return unit.saveStartList({ unitId, participantsIds })
      },
      createSimpleHandler('submittingSaveStartList', store),
      createSimpleHandler('submitSaveStartListSucceeded', store, payload),
      createSimpleHandler('submitSaveStartListFailed', store)
    )
  },
  async [actions.saveJudgeList] (store, { unitId, panels }) {
    const payload = { unitId }
    await callApi(
      async () => {
        return unit.saveJudgeList({ unitId, panels })
      },
      createSimpleHandler('submittingSaveJudgeList', store),
      createSimpleHandler('submitSaveJudgeListSucceeded', store, payload),
      createSimpleHandler('submitSaveJudgeListFailed', store)
    )
  },
  [actions.unitStatusChangeStarted] ({ commit }) {
    commit(mutations.setUnitStateChangeInProgress, true)
  },
  [actions.unitStatusChangeFinished] ({ commit }) {
    commit(mutations.setUnitStateChangeInProgress, false)
  },
  async [actions.postResetUnit] (store, { unitId }) {
    const payload = { unitId }
    await callApi(
      async () => {
        return unit.resetUnit({ unitId })
      },

      createSimpleHandler('submittingResetUnit', store),
      createSimpleHandler('submitResetUnitSucceeded', store, payload),
      createSimpleHandler('submitResetUnitFailed', store))
  },
  [actions.updateUnitStatus] ({ commit }, { unitId, status }) {
    const loweredStatus = status.toLowerCase()

    commit(mutations.updateUnit, {
      id: unitId,
      status: loweredStatus
    })
  }
}
