<template>
  <div v-if="authorized">
    <go-to-current-participant-button/>
    <Menu :authState="authState"/>
    <div class="settings">
      <h1>Settings</h1>
      <v-form v-model="isFormValid">
        <v-simple-table
          class="settings__form"
          fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Property
                </th>
                <th class="text-left">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-once>Competition name</td>
                <td>
                  <settings-text
                  :validation="[required]"
                  :settingValue="allSettings.competitionName.value"
                  :isDisabled = "allSettings.competitionName['read-only']"
                  @changed="(value) => onSettingChanged({value, key:'competitionName.value'})"/>
                </td>
              </tr>
              <tr>
                <td v-once>Synchronization error time frame</td>
                <td>
                  <settings-slider
                    :max="5000"
                    :min="250"
                    :settingValue="allSettings.synchroTimeframeWindow.value"
                    :isDisabled = "allSettings.synchroTimeframeWindow['read-only']"
                    units="ms"
                    @changed="(value) => onSettingChanged({value, key:'synchroTimeframeWindow.value'})"/>
                </td>
              </tr>
              <tr>
                <td>Elements judges count</td>
                <td>
                  <settings-slider
                    :max="5"
                    :min="1"
                    :settingValue="allSettings.judges.value.requiredElements"
                    :isDisabled = "allSettings.judges['read-only']"
                    @changed="(value) => onSettingChanged({value, key:'judges.value.requiredElements'})"/>
                </td>
              </tr>
              <tr>
                <td>Artistic Impression judges count</td>
                <td>
                  <settings-slider
                    :max="5"
                    :min="1"
                    :settingValue="allSettings.judges.value.requiredAI"
                    :isDisabled = "allSettings.judges['read-only']"
                    @changed="(value) => onSettingChanged({value, key:'judges.value.requiredAI'})"/>
                </td>
              </tr>
              <tr>
                <td>Difficulty Technical Controller judges count</td>
                <td>
                  <settings-slider
                    :max="1"
                    :min="0"
                    :settingValue="allSettings.judges.value.requiredDTC"
                    :isDisabled = "allSettings.judges['read-only']"
                    @changed="(value) => onSettingChanged({value, key:'judges.value.requiredDTC'})"/>
                </td>
              </tr>
              <tr>
                <td>Difficulty Assistant Technical Controller  judges count</td>
                <td>
                  <settings-slider
                    :max="2"
                    :min="0"
                    :settingValue="allSettings.judges.value.requiredDATC"
                    :isDisabled = "allSettings.judges['read-only']"
                    @changed="(value) => onSettingChanged({value, key:'judges.value.requiredDATC'})"/>
                </td>
              </tr>
              <tr>
                <td>Coach Card push enabled</td>
                <td>
                  <CoachCardPushEnabledSettingsSwitch
                    @changed="(value) => onSettingChanged({value, key:'coachCardPushEnabled.value'})"
                    :coachCardPushEnabled="allSettings.coachCardPushEnabled.value"
                    :isDisabled = "allSettings.coachCardPushEnabled['read-only']"
                  />
                </td>
              </tr>
              <tr>
                <td>Timezone</td>
                <td>
                  <settings-text
                    :settingValue="allSettings.timezone.value"
                    :isDisabled = "allSettings.timezone['read-only']"
                    :validation="[required, timezoneValidation]"
                    @changed="(value) => onSettingChanged({value, key:'timezone.value'})" />
                </td>
              </tr>
              <tr>
                <td>Enable 'one country/one rank' rule?</td>
                <td>
                  <settings-switch
                    :settingValue="allSettings.oneCountryOneRankEnabled.value"
                    :isDisabled = "allSettings.oneCountryOneRankEnabled['read-only']"
                    switch-label="One country/one rank enabled"
                    @changed="(value) => onSettingChanged({value, key:'oneCountryOneRankEnabled.value'})"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable automatic ODF generation</td>
                <td>
                  <settings-switch
                    :settingValue="allSettings.automaticODFGeneration.value"
                    :isDisabled = "allSettings.automaticODFGeneration['read-only']"
                    switch-label="Automatic ODF generation"
                    @changed="(value) => onSettingChanged({value, key:'automaticODFGeneration.value'})"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable automatic Publisher</td>
                <td>
                  <settings-switch
                    :settingValue="allSettings.automaticPublisher.value"
                    :isDisabled = "allSettings.automaticPublisher['read-only']"
                    switch-label="Automatic Publisher"
                    @changed="(value) => onSettingChanged({value, key:'automaticPublisher.value'})"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable test mode</td>
                <td>
                  <settings-switch
                    :settingValue="allSettings.testMode.value"
                    :isDisabled = "allSettings.testMode['read-only']"
                    switch-label="Test mode"
                    @changed="(value) => onSettingChanged({value, key:'testMode.value'})"
                  />
                </td>
              </tr>
              <tr>
                <td v-once>Scores sync throttle</td>
                <td>
                  <settings-slider
                    :min="0"
                    :max="10000"
                    :settingValue="allSettings.scoresSyncThrottle.value"
                    :isDisabled = "allSettings.scoresSyncThrottle['read-only']"
                    units="ms"
                    @changed="(value) => onSettingChanged({value, key:'scoresSyncThrottle.value'})"/>
                </td>
              </tr>
              <tr>
                <td v-once>Synchronization errors sync throttle</td>
                <td>
                  <settings-slider
                    :min="0"
                    :max="10000"
                    :settingValue="allSettings.synchroScoresSyncThrottle.value"
                    :isDisabled = "allSettings.synchroScoresSyncThrottle['read-only']"
                    units="ms"
                    @changed="(value) => onSettingChanged({value, key:'synchroScoresSyncThrottle.value'})"/>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <v-btn
        :disabled="!areSettingsTouched || !isFormValid"
        :loading="isSettingsSaveInProgress"
        color="primary"
        @click="onSaveSettingsClicked">Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import SettingsSlider from '@/components/settings/SettingsSlider'
import SettingsText from '@/components/settings/SettingsText'
import SettingsSwitch from '@/components/settings/SettingsSwitch'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { mapActions, mapGetters } from 'vuex'
import { configuration, contextSelection } from '@/store/modules'
import { authorize, setupAuthorization } from '@/services/auth-service'
import CoachCardPushEnabledSettingsSwitch from '@/components/settings/CoachCardPushEnabledSettingsSwitch.vue'

export default {
  name: 'Settings',
  components: {
    CoachCardPushEnabledSettingsSwitch,
    SettingsText,
    SettingsSlider,
    SettingsSwitch,
    Menu,
    GoToCurrentParticipantButton
  },
  data () {
    return {
      areSettingsTouched: false,
      isFormValid: false,
      ...setupAuthorization({ component: this, requiredRoles: ['olr-operator'] })
    }
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  },
  computed: {
    ...mapGetters(
      configuration.namespace,
      [
        configuration.getters.isSettingsSaveInProgress,
        configuration.getters.allSettings
      ]
    ),
    ...mapGetters(
      contextSelection.namespace,
      [
        contextSelection.getters.selectedContext
      ]
    )
  },
  methods: {
    ...mapActions(
      configuration.namespace,
      [
        configuration.actions.saveSettings,
        configuration.actions.updateSetting
      ]
    ),
    required (v) {
      return !!v || 'Field is required'
    },
    timezoneValidation (v) {
      const pattern = /[+|-]\d\d:\d\d/sg
      if (!v?.match(pattern)) {
        return 'Timezone must be in format +00:00 or -00:00'
      }
      const parsedMinutes = parseInt(v?.split(':')[1])
      const parsedHours = parseInt(v?.split(':')[0].slice(1))
      if (parsedMinutes > 59) {
        return 'Minutes must be less than 60'
      }
      if (parsedHours > 14) {
        return 'Hours must be less than 14'
      }
      return true
    },
    onSettingChanged ({ key, value }) {
      this.updateSetting({ key, value })
      this.areSettingsTouched = true
    },
    onSaveSettingsClicked () {
      if (this.isFormValid) {
        this.areSettingsTouched = false
        this.saveSettings()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.settings {
  &__form table > thead > tr > th {
    &:first-of-type {
      min-width: 30%;
      width: 30%;
    }
  }
}
td {
  vertical-align: center;
  text-align: left;
}
</style>
