import { callApi, configuration, createSimpleHandler, dispatchGlobal } from '@/api'
import { getters } from './getters'
import { mutations } from './mutations'
import { mapValues } from 'lodash'

export const actions = {
  fetchConfiguration: 'fetchConfiguration',
  saveSettings: 'saveSettings',
  updateSetting: 'updateSetting'
}

const handleConfigurationChange = localGetters => {
  if (localGetters[getters.isUserSnapEnabled]) {
    window.onUsersnapCXLoad = function (api) {
      api.init()
    }
    const userSnapUri = localGetters[getters.getUserSnapUri]
    const userSnapScript = document.createElement('script')
    userSnapScript.setAttribute('src', userSnapUri || '')
    document.head.appendChild(userSnapScript)
  }
}

const createGlobalConfigurationSucceededHandler = ({
  dispatch,
  commit,
  getters: localGetters
}) => configuration => {
  commit(mutations.setFeatureFlags, configuration)
  commit(mutations.setSettings, configuration)
  dispatchGlobal(dispatch, 'fetchConfigurationSucceeded')

  handleConfigurationChange(localGetters)
}

export const actionsDefinition = {
  async [actions.fetchConfiguration] (store) {
    return await callApi(
      async () => configuration.configuration(),
      createSimpleHandler('fetchingConfiguration', store),
      createGlobalConfigurationSucceededHandler(store),
      createSimpleHandler('fetchConfigurationFailed', store)
    )
  },

  async [actions.saveSettings] (store) {
    const mappedSettings = mapValues(store.getters[getters.allSettings], 'value')
    await callApi(
      async () => configuration.saveSettings(mappedSettings),
      createSimpleHandler('submittingSaveSettings', store),
      createSimpleHandler('submitSaveSettingsSucceeded', store),
      createSimpleHandler('submitSaveSettingsFailed', store)
    )
  },

  [actions.updateSetting] ({ commit }, { key, value }) {
    commit(mutations.updateSetting, {
      key,
      value
    })
  }

}
