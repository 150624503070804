import { map } from 'lodash'
import SceneId from '@/enums/SceneId'

export const tournamentNodeContextualScenes =
  [
    SceneId.Opener,
    SceneId.StartList,
    SceneId.Judges,
    SceneId.Results,
    SceneId.Live,
    SceneId.Score,
    SceneId.TechnicalReview,
    SceneId.Podium,
    SceneId.TeamComposition,
    SceneId.Freetext
  ]

export const defaultState = {
  selectedUnit: undefined,
  selectedRegisteredParticipants: [],
  selectedRegisteredJudges: [],
  configuration: {},
  competitionPrintoutsStatuses: [],
  eventPrintoutsStatuses: [],
  unitPrintoutsStatuses: [],
  inProgressCounters: {},
  odfImportResult: undefined,
  coachCardImportResult: undefined,
  selectedAllocatedJudges: [],
  cancellationTokens: {},
  participants: [],
  odfMessages: [],
  pscbScenes: [],
  isConnectedPublisher: undefined
}

export const inProgress = {
  odfImport: 'odfImport',
  coachCardImport: 'coachCardImport',
  saveJudgeAllocation: 'judgesAllocationSave',
  saveStartList: 'startListSave',
  changeUnitStatus: 'unitStatusChange',
  registeredParticipantsLoading: 'registeredParticipantsLoading',
  unitParticipantsLoading: 'unitParticipantsLoading',
  registeredJudgesLoading: 'registeredJudgesLoading',
  allocatedJudgesLoading: 'allocatedJudgesLoading',
  odfMessageGeneration: 'odfMessageGeneration',
  printoutGeneration: 'printoutGeneration',
  pscbAllOff: 'pscbAllOff',
  displayScene: 'displayScene'
}

export const mutations = {
  setSelectedUnit: 'setSelectedUnit',
  setRegisteredParticipants: 'setRegisteredParticipants',
  setParticipants: 'setParticipants',
  setParticipantStatus: 'setParticipantStatus',
  clearParticipants: 'clearParticipants',
  clearSelectedRegisteredParticipants: 'clearSelectedRegisteredParticipants',
  setRegisteredJudges: 'setRegisteredJudges',
  clearSelectedRegisteredJudges: 'clearSelectedRegisteredJudges',
  setAllocatedJudges: 'setAllocatedJudges',
  clearSelectedJudges: 'clearSelectedJudges',
  setConfiguration: 'setConfiguration',
  setCompetitionPrintoutsStatuses: 'setCompetitionPrintoutsStatuses',
  setEventPrintoutsStatuses: 'setEventPrintoutsStatuses',
  setUnitPrintoutsStatuses: 'setUnitPrintoutsStatuses',
  setOdfImportResult: 'setOdfImportResult',
  setCoachCardImportResult: 'setCoachCardImportResult',
  replaceCancellationTokenForContext: 'replaceCancellationTokenForContext',
  setOdfMessages: 'setOdfMessages',
  setOdfMessage: 'setOdfMessage',
  setPscbScenes: 'setPscbScenes',
  addInProgress: 'addInProgress',
  releaseInProgress: 'releaseInProgress',
  addObjectInProgress: 'addObjectInProgress',
  releaseObjectInProgress: 'releaseObjectInProgress',
  setIsConnectedPublisher: 'setIsConnectedPublisher'
}

export const mutationsDefinition = {
  [mutations.setSelectedUnit]: (store, selectedUnit) => {
    store.selectedUnit = selectedUnit
  },
  [mutations.setRegisteredParticipants]: (
    store,
    eventRegisteredParticipants
  ) => {
    store.selectedRegisteredParticipants = eventRegisteredParticipants
  },
  [mutations.setParticipants]: (store, { participants } = {}) => {
    store.participants = map(participants, (participant = {}) => ({
      id: participant?.id,
      noc: participant?.noc,
      members: participant?.members,
      status: participant?.status,
      totalScore: participant?.totalScore,
      irm: participant?.irm,
      hasCoachCard: participant?.hasCoachCard
    }))
  },
  [mutations.setParticipantStatus]: (store, { participantId, status }) => {
    store.participants = map(store.participants, participant =>
      participant.id === participantId ? { ...participant, status } : participant
    )
  },
  [mutations.clearParticipants]: store => {
    store.participants = []
  },
  [mutations.clearSelectedRegisteredParticipants]: store => {
    store.selectedRegisteredParticipants = []
  },
  [mutations.setRegisteredJudges]: (store, eventRegisteredJudges) => {
    store.selectedRegisteredJudges = eventRegisteredJudges
  },
  [mutations.clearSelectedRegisteredJudges]: store => {
    store.selectedRegisteredJudges = []
  },
  [mutations.setAllocatedJudges]: (store, { judges } = {}) => {
    store.selectedAllocatedJudges = judges
  },
  [mutations.clearSelectedJudges]: store => {
    store.selectedAllocatedJudges = []
  },
  [mutations.setConfiguration]: (store, configuration) => {
    store.configuration = configuration
  },
  [mutations.setCompetitionPrintoutsStatuses]: (
    store,
    competitionPrintoutsStatuses
  ) => {
    store.competitionPrintoutsStatuses = competitionPrintoutsStatuses
  },
  [mutations.setEventPrintoutsStatuses]: (store, eventPrintoutsStatuses) => {
    store.eventPrintoutsStatuses = eventPrintoutsStatuses
  },
  [mutations.setUnitPrintoutsStatuses]: (store, unitPrintoutsStatuses) => {
    store.unitPrintoutsStatuses = unitPrintoutsStatuses
  },
  [mutations.setOdfImportResult]: (store, result) => {
    store.odfImportResult = result
  },
  [mutations.setCoachCardImportResult]: (store, result) => {
    store.coachCardImportResult = result
  },
  [mutations.replaceCancellationTokenForContext]: (store, payload) => {
    store.cancellationTokens = {
      ...store.cancellationTokens,
      ...payload
    }
  },
  [mutations.setOdfMessages]: (store, messages) => {
    store.odfMessages = messages
  },
  [mutations.setOdfMessage]: (
    store,
    { name, lastGeneratedAt, errors }
  ) => {
    store.odfMessages = [
      ...store.odfMessages.filter(message => message.name !== name),
      { name, lastGeneratedAt, errors }
    ]
  },
  [mutations.setPscbScenes]: (store, scenes) => {
    store.pscbScenes = scenes
  },
  [mutations.addInProgress]: (store, { scope }) => {
    const state = store.inProgressCounters[scope] ?? 0
    store.inProgressCounters = {
      ...store.inProgressCounters,
      [scope]: state + 1
    }
  },
  [mutations.releaseInProgress]: (store, { scope }) => {
    const state = store.inProgressCounters[scope] ?? 0
    store.inProgressCounters = {
      ...store.inProgressCounters,
      [scope]: Math.max(0, state - 1)
    }
  },
  [mutations.addObjectInProgress]: (store, { path }) => {
    const [propName, id] = path
    const propState = store.inProgressCounters[propName] ?? {}
    store.inProgressCounters = {
      ...store.inProgressCounters,
      [propName]: {
        ...propState,
        [id]: (propState[id] ?? 0) + 1
      }
    }
  },
  [mutations.releaseObjectInProgress]: (store, { path }) => {
    const [propName, id] = path
    const propState = store.inProgressCounters[propName] ?? {}
    store.inProgressCounters = {
      ...store.inProgressCounters,
      [propName]: {
        ...propState,
        [id]: Math.max(0, (propState[id] ?? 0) - 1)
      }
    }
  },
  [mutations.setIsConnectedPublisher]: (store, isConnectedPublisher) => {
    store.isConnectedPublisher = isConnectedPublisher
  }
}
