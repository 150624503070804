<template>
  <section
    class="schedule-list">
    <span v-if='!this.hasUnits'>
      <span class='schedule-list__title'>
        Schedule
      </span>
      <schedule-wizard
        :editedEventId="editedEventId"
        @closed="editedEventId = null" />
    </span>
    <span v-else>
      <schedule-wizard
        :editedEventId="editedEventId"
        @closed="editedEventId = null" />
      <ol class="schedule-list__items-list">
        <li v-for="({ level, date, endDate, name, unit }, key) in this.displayableUnits"
          :key="key"
          :class="`
            schedule-list__items-list-item
            schedule-list__items-list-item--level-${level}`">
          <div
            v-if="level === 'event'"
            class='
              schedule-list__items-list-item-part
              schedule-list__items-list-item-part--date'>
            {{ date }}
          </div>
          <div
            v-if="level === 'unit'"
            class='
              schedule-list__items-list-item-part
              schedule-list__items-list-item-part--name'
            @click='setSelectedUnit(unit)'>
            {{ date }} - {{endDate}} {{ name }} [{{unit.status}}]
          </div>
          <drop-down-grouped-menu
            v-if="level === 'unit'"
            v-model="showMenu[unit.id]"
            :options="options"
            @optionSelected="onOptionSelected($event, unit)"
            @menuOpened="onStatusMenuOpened(unit)"/>
          <flat-spinner
            v-if="level === 'unit'"
            :active="unitsChangeInProgress[unit.id] === true"
            class='schedule-list__items-list-item-part-progress' />
        </li>
      </ol>
    </span>
  </section>
</template>

<script>
import DropDownGroupedMenu from '@/components/DropDownGroupedMenu'
import FlatSpinner from '@/components/FlatSpinner'
import ScheduleWizard from '@/components/schedule-wizard/ScheduleWizard'
import { UnitStatus } from '@/enums/UnitStatus'
import { UnitStatusMap } from '@/maps/UnitStatusMap'
import { olr, unit } from '@/store/modules'
import { flatMap, groupBy, orderBy, isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

const deleteOption = 'delete event'
const resetUnitOption = 'reset unit'
const editEventOption = 'edit event'

export default {
  name: 'ScheduleList',
  components: {
    FlatSpinner,
    DropDownGroupedMenu,
    ScheduleWizard
  },
  async created () {
    await this.fetchAll()
  },
  data: () => ({
    showMenu: {},
    menuUnit: null,
    editedEventId: null
  }),
  methods: {
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchAll,
        unit.actions.postResetUnit
      ]),
    ...mapActions(
      olr.namespace,
      [
        olr.actions.setSelectedUnit,
        olr.actions.deleteScheduleEntry,
        olr.actions.modifyStatus,
        olr.actions.fetchAllParticipants
      ]
    ),
    getDatePart (dateTime) {
      return dateTime && dateTime.slice(0, 10)
    },
    getTimePart (dateTime) {
      return dateTime && dateTime.slice(11, 16)
    },
    async onStatusMenuOpened (unit) {
      this.menuUnit = unit
    },
    async onOptionSelected ({ group, option }, unit) {
      const { id: unitId, eventId } = unit

      if (group === 'Actions') {
        switch (option) {
          case deleteOption:
            await this.deleteScheduleEntry({ eventId })
            await this.fetchAll()
            break
          case resetUnitOption:
            await this.postResetUnit({ unitId })
            break
          case editEventOption:
            this.editedEventId = eventId
            break
        }
      } else if (group === 'Statuses') {
        await this.modifyStatus({
          unitId,
          expectedStatus: UnitStatusMap[option]
        })
      }

      this.showMenu[unitId] = false
    }
  },
  computed: {
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.allUnits
      ]
    ),
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.unitsChangeInProgress,
        olr.getters.allParticipants
      ]
    ),
    hasUnits () {
      return this.allUnits?.length > 0
    },
    displayableUnits () {
      const orderedUnits = orderBy(this.allUnits, ['scheduledDateStart'], ['asc'])
      return flatMap(
        groupBy(orderedUnits, ({ scheduledDateStart }) => this.getDatePart(scheduledDateStart)),
        (units = [], date) => [
          { date, level: 'event' },
          ...units.map(({ scheduledDateStart, scheduledDateEnd, name, ...unit }) => ({
            date: this.getTimePart(scheduledDateStart),
            endDate: this.getTimePart(scheduledDateEnd),
            name,
            level: 'unit',
            unit: { scheduledDateStart, name, ...unit }
          }))])
    },
    options () {
      if (isEmpty(this.menuUnit)) {
        return []
      }

      const statusesOptions = Object
        .keys(UnitStatus)
        .map(key => ({
          title: UnitStatus[key],
          disabled:
            UnitStatus[key] === UnitStatus.Running &&
            (isEmpty(this.allParticipants) || !this.allParticipants.every(p => p.hasCoachCard))
        }))

      return [
        {
          group: 'Actions',
          items: [
            {
              title: deleteOption,
              disabled: false,
              promptTitle: 'Confirm schedule entry deletion',
              promptMessageLines: [
                'Are you sure you want to delete schedule entry?',
                'It will result in deletion of all related units.'
              ]
            },
            {
              title: resetUnitOption,
              disabled: false,
              promptTitle: 'Confirm unit reset',
              promptMessageLines: [
                'Are you sure you want to reset unit?',
                'This operation is irreversible and lead to removal of all scores and athletes progress within the unit.'
              ]
            },
            {
              title: editEventOption,
              disabled: false
            }
          ]
        },
        {
          group: 'Statuses',
          items: statusesOptions
        }
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
@use "sass:map";
@import "~@/styles/container";
@import "~@/styles/button";
@import "~@/styles/variables";
@import "~@/styles/typology";

$height: 25rem;
$size: $small;
.schedule-list {
  @include border($radius: 1rem);

  position: relative;
  margin: 1rem 0;
  max-height: $height;

  &__title {
    @include button(false, $size);

    position: absolute;
    top: -0.75rem;
    left: 0.5rem;

    margin: 0;
  }

  &__wizard {
    @include secondary-caption-l4;
    margin: 0.5rem 0 1rem 0;
    padding: 0;
  }

  &__items-list {
    max-height: #{$height - 2.5rem - map.get($size, 'default')};

    margin: 0;
    padding: 0;

    overflow: auto;

    &-item {
      @include container("justified", "center");
      @include primary-bold-caption-l5;
      position: relative;

      margin: 0 1rem;

      list-style-type: none;
      text-align: left;

      &--level {
        &-event {
          border: $lightOrange solid 0.1rem;
          background: $lightYellow;

          margin-left: 1rem;
        }

        &-unit {
          border: $orange solid 0.1rem;
          background: $lightPink;

          margin-left: 2rem;
        }
      }

      &-part {
        padding: 0.3rem 1rem;

        &-progress {
          position: absolute;
          bottom: 0;
        }

        &--name {
          cursor: pointer;
          width: 100%;
        }

        &--date {
          width: 100%;
        }
      }
    }
  }
}
</style>
